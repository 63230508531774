import { GET_QUARANTINE_EMAILS } from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_QUARANTINE_EMAILS:
      return action.payload || null;
    default:
      return state;
  }
};
