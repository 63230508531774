import React from 'react';
import QuarantineDestinationFilter from '../QuarantineDestinationFilter/QuarantineDestinationFilter';
import SCQuarantineFilterContainer from './QuarantineDestinationFilterContainer.style';

const QuarantineDestinationFilterContainer = ({
  selectedQuarantineDestinations,
  onChangeQuarantineDestinationCallback,
  opened,
}) => {
  const applyTagFilterChanges = (filterChanges) => {
    if (onChangeQuarantineDestinationCallback) {
      onChangeQuarantineDestinationCallback(filterChanges);
    }
  };

  const unsetFilter = () => {
    applyTagFilterChanges([]);
  };

  return (
    <SCQuarantineFilterContainer opened={opened}>
      <div className="QuarantineDestinationFilterContainer_MainContainer">
        <QuarantineDestinationFilter
          onModify={applyTagFilterChanges}
          onRemove={unsetFilter}
          initialQuarantineDestinations={selectedQuarantineDestinations}
        />
      </div>
    </SCQuarantineFilterContainer>
  );
};

export default QuarantineDestinationFilterContainer;
