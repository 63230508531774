import styled from '@emotion/styled';

const SCMail = styled.div`
  .section-organism {
    svg {
      margin-right: 8px;
    }
  }
  .Mail_Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 319px;
    }
  }
`;

export default SCMail;
