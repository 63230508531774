import styled from '@emotion/styled';
import headerBackgroundImage from '../../img/leadsPage/header-background.jpg';

const SCLeadsPage = styled.main`
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: var(--navbar-height);
    background-image: url(${headerBackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    height: 250px;
    width: 100%;

    .background-image {
      backdrop-filter: blur(10px);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .header-content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      width: calc(100% - 20px);
      max-width: 640px;

      min-height: 175px;
      background-color: white;
      border-radius: 5px;

      .image-container {
        width: ${({ isCustomLeadPage }) => isCustomLeadPage && '90%'};
        max-width: ${({ isCustomLeadPage }) =>
          isCustomLeadPage ? '100%' : '290px'};
        max-height: 120px;
        margin: auto;

        padding: 20px 0px;
        > img {
          max-height: calc(120px - 40px);
          height: 100%;
          width: 100%;
        }
      }

      .dates-container {
        display: flex;
        flex-direction: column;
        background-color: var(--light-grey);
        min-height: 38px;
        width: 100%;
        justify-content: center;
        align-items: center;

        padding: 10px 0px;
        border-radius: 0px 0px 5px 5px;
        .dates-separator {
          display: none;
        }

        > p {
          font-family: var(--font1);
          font-size: 16px;
          color: var(--bluish-grey);
          text-align: center;
          font-weight: 400;
        }

        strong {
          margin: 0px 5px;
          font-weight: 600;
        }
      }
    }
  }

  .top-info-container {
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    .left-container {
      .left-container-content {
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;
        padding: 45px 20px;

        > h1 {
          font-size: 32px;
          color: var(--bluish-grey);
          text-align: center;
        }

        > p {
          font-family: var(--font1);
          font-size: 16px;
          color: var(--bluish-grey);
          font-weight: 400;
          text-align: center;
        }

        .report-contain-container {
          display: flex;
          flex-direction: column;
          gap: 25px;

          > h2 {
            font-size: 16px;
            font-weight: 500;
          }

          .feature-checks-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          .assessment-check {
            display: flex;
            align-items: center;
            gap: 10px;

            > p {
              font-family: var(--font1);
              font-size: 16px;
              color: var(--bluish-grey);
              font-weight: 400;
            }
          }
        }
      }
    }

    .right-container {
      background: var(--light-grey);

      .right-container-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 45px 20px;
      }
    }
  }

  .how-it-works-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-grey);
    gap: 70px;
    padding: 20px;
    width: 100%;

    > h2 {
      color: var(--bluish-grey);
      font-size: 35px;
      font-weight: 500;
      text-align: center;
    }

    .how-it-works-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 60px;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;

      .card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        padding: 20px;
        height: auto;
        width: 100%;
        height: 470px;
        border-radius: 5px;
        box-shadow: 2px 2px 10px 10px rgb(0 0 0 / 2%);

        .index-number-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          border-radius: 50vh;
          background-color: var(--red);
          color: #fff;
          font-size: 25px;
        }

        > h3 {
          color: var(--bluish-grey);
          font-size: 28px;
          font-weight: 500;
        }

        > p {
          font-family: var(--font1);
          font-weight: 400;
        }

        > img {
          height: 180px;
        }
      }
    }
  }

  @media (min-width: 769px) {
    .header-container {
      background-size: 120%;
      .header-content-container {
        .image-container {
          padding: 0px;
          > img {
            max-height: 120px;
          }
        }
        .dates-container {
          flex-direction: row;

          .dates-separator {
            margin: 0px 5px;
            display: block;
          }
        }
      }
    }

    .top-info-container {
      flex-direction: row;

      .left-container {
        width: 50%;
        padding-right: 20px;
        padding-left: 20px;
        display: flex;
        justify-content: flex-end;

        .left-container-content {
          max-width: 660px;
          padding: 40px 0px;

          > h1 {
            font-size: 32px;
            text-align: left;
          }

          > p {
            text-align: left;
          }
        }
      }

      .right-container {
        width: 50%;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: flex-start;

        .right-container-content {
          max-width: 660px;
          padding: 40px 0px;
        }
      }
    }
    .how-it-works-container {
      padding: 100px;
    }
  }

  @media (min-width: 1280px) {
    .header-container {
      background-size: 120%;
      .header-content-container {
        .dates-container {
          flex-direction: row;
          padding: 0;

          .dates-separator {
            display: block;
          }
        }
      }
    }

    .top-info-container {
      flex-direction: row;

      .left-container {
        width: 50%;
        padding-right: 100px;
        padding-left: 40px;
        display: flex;
        justify-content: flex-end;

        .left-container-content {
          max-width: 660px;
          padding: 90px 0px;

          > h1 {
            font-size: 50px;
            text-align: left;
          }

          > p {
            text-align: left;
          }
        }
      }

      .right-container {
        width: 50%;
        padding-left: 100px;
        padding-right: 40px;
        display: flex;
        justify-content: flex-start;

        .right-container-content {
          max-width: 660px;
          padding: 90px 0px;
        }
      }
    }
    .how-it-works-container {
      padding: 100px;
    }
  }
`;

export default SCLeadsPage;
