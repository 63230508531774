import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { useQuarantineEmails } from '../../../hooks/useQuarantineEmails/useQuarantineEmails';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemoveQuarantineEmail = (props) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const { deleteQuarantineEmail, quarantineEmails } = useQuarantineEmails();

  const dispatch = useDispatch();
  const { emailInfo } = props;

  useEffect(() => {
    if (quarantineEmails) {
      const auxMailInfo = quarantineEmails.find((quarantineEmail) => {
        return quarantineEmail.guid === emailInfo.guid;
      });

      if (!auxMailInfo) {
        dispatch(showPopUp(null));
        navigate('/client/mailbox-security');
      }
    }
  }, [quarantineEmails]);

  const remove = () => {
    deleteQuarantineEmail({ emailId: emailInfo.id })
      .then((resp) => {})
      .catch((e) => {
        dispatch(showPopUp(null));
        Swal.fire(i18n.t('common.error'), i18n.t('common.errorTryLater'));
      });
  };

  const cancel = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <Wrapper>
        <h1>{i18n.t('mailbox-security.mail.removePopUp.header')}</h1>

        <div className="modal-card-buttons">
          <Button
            color="bluishGrey"
            size="full"
            type="button"
            text={i18n.t('mailbox-security.mail.removePopUp.actions.remove')}
            onClick={() => {
              remove();
            }}
          />
          <Button
            color="white"
            size="full"
            type="button"
            text={i18n.t('mailbox-security.mail.removePopUp.actions.cancel')}
            onClick={() => {
              cancel();
            }}
          />
        </div>
      </Wrapper>
    </ModalLayout>
  );
};

export default RemoveQuarantineEmail;
