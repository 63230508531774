import React, { useState } from 'react';
import {
  faDesktop,
  faEye,
  faMobile,
  faServer,
  faShieldPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAndroid,
  faApple,
  faWindows,
  faLinux,
} from '@fortawesome/free-brands-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import SCDevicesSummary, { SCTypeIcons } from './DevicesSummary.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import Icon from '../../atoms/Icon/Icon';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import useLicenses from '../../../hooks/licenses/useLicenses';
import Label from '../../atoms/Label/Label';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';

const getOSName = (osDBName) => osDBName.split(' ')[0].toLowerCase();

const normalizeDeviceType = (deviceType) => deviceType.toLowerCase();

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
  MOBILE: 'mobile',
};

const DevicesSummary = () => {
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const protectedDevices = useSelector((redux) => redux.protectedDevices);
  const { availableLicensesToSend } = useLicenses();

  const i18n = useTranslation();
  const navigate = useNavigate();

  const [deviceFilter, setDeviceFilter] = useState([]);

  const getTeamMembersTags = () => {
    const teamMembersMap = {};

    teamMembers.forEach(({ email, tags, role }) => {
      teamMembersMap[email] = { tags: tags ?? [], role };
    });

    return teamMembersMap;
  };

  const teamMembersMap = getTeamMembersTags();

  const protectedDevicesWithTeamMember = protectedDevices?.map(
    (protectedDevice) => ({
      ...protectedDevice,
      ...teamMembersMap[protectedDevice.email],
    })
  );

  const filteredProtectedDevices =
    deviceFilter.length > 0
      ? protectedDevicesWithTeamMember.filter((protectedDevice) => {
          return deviceFilter.includes(
            normalizeDeviceType(protectedDevice.type)
          );
        })
      : protectedDevicesWithTeamMember;

  const deviceTypeIcons = (type, os) => {
    const iconsProps = {
      color: 'var(--bluish-grey-2)',
      size: 'medium',
    };

    const ICONS = {
      unknown: faCircleQuestion,
      type: {
        server: faServer,
        desktop: faDesktop,
        mobile: faMobile,
      },
      os: {
        windows: faWindows,
        macos: faApple,
        android: faAndroid,
        ios: faApple,
        linux: faLinux,
      },
    };

    return (
      <SCTypeIcons>
        <Icon iconName={ICONS.type[type] ?? ICONS.unknown} {...iconsProps} />
        <Icon iconName={ICONS.os[os] ?? ICONS.unknown} {...iconsProps} />
      </SCTypeIcons>
    );
  };

  const getWebProtectionText = (webProtectionActive, deviceType) => {
    if (deviceType !== DEVICE_TYPES.DESKTOP) {
      return i18n.t('deviceSecurity.deviceSecuritySummary.notApplicable');
    }

    return webProtectionActive
      ? i18n.t('deviceSecurity.deviceSecuritySummary.active')
      : i18n.t('deviceSecurity.deviceSecuritySummary.inactive');
  };

  const mapperFn = (item) => {
    return {
      email: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{item?.email}</p>}>
            <p>{item?.email}</p>
          </Tooltip>
        ),
        sortValue: item?.email,
      },
      device: {
        type: 'string',
        value: item?.devicename,
        sortValue: item?.devicename,
      },
      deviceType: {
        type: 'component',
        value: deviceTypeIcons(
          normalizeDeviceType(item?.type),
          getOSName(item?.osversion)
        ),
      },
      deviceProtection: {
        type: 'component',
        value: (
          <Label
            value={
              item?.malware?.active
                ? i18n.t('deviceSecurity.deviceSecuritySummary.active')
                : i18n.t('deviceSecurity.deviceSecuritySummary.inactive')
            }
            color={!item?.malware?.active && 'red'}
          />
        ),
        sortValue: item?.malware?.active
          ? i18n.t('deviceSecurity.deviceSecuritySummary.active')
          : i18n.t('deviceSecurity.deviceSecuritySummary.inactive'),
      },
      webProtection: {
        type: 'component',
        value: (
          <Label
            value={getWebProtectionText(
              item?.webprotection?.active,
              normalizeDeviceType(item?.type)
            )}
            color={
              normalizeDeviceType(item?.type) === DEVICE_TYPES.DESKTOP &&
              !item?.webprotection?.active &&
              'red'
            }
          />
        ),
        sortValue: getWebProtectionText(
          item?.webprotection?.active,
          normalizeDeviceType(item?.type)
        ),
        color:
          normalizeDeviceType(item?.type) === DEVICE_TYPES.DESKTOP &&
          !item?.webprotection?.active
            ? 'var(--red)'
            : 'initial',
      },
      role: {
        type: 'component',
        value: item.tags ? <TagList teamMember={item} cell /> : undefined,
      },
    };
  };

  const PAGE_SIZE = 10;

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(
      `deviceSecurity.deviceSecuritySummary.email`
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const {
    currentPage,
    setSort,
    setSearch,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    filteredProtectedDevices,
    ['email', 'devicename'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.email`),
      options: {
        ofuscateFS: true,
        stickyOnScroll: true,
        stickyWidth: 250,
        stickyOffset: 0,
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.device`),
      options: {
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.deviceType`),
      options: {
        avoidSort: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.deviceProtection`),
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.webProtection`),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCDevicesSummary>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'deviceSecurity.deviceSecuritySummary.overviewOfMyDevices'
        )} (${length})`}>
        <div className="infoBanner-container">
          <InfoBanner
            type="defaultStyle"
            text={[
              i18n.t('deviceSecurity.youHaveAvailableLicenses', {
                availableLicensesToSend,
              }),
            ]}
            onClickButton={() =>
              navigate('/client/device-security/install-protections')
            }
            buttonText={i18n.t(
              'deviceSecurity.deviceSecuritySummary.installProtections'
            )}
            buttonIcon={faShieldPlus}
          />
        </div>

        <TableContainer
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t(
            'deviceSecurity.deviceSecuritySummary.noDevices'
          )}
          onPageChange={(page) => setPage(page)}
          onChangeValue={setSearch}
          onChangeFilterTag={setSearchTags}
          totalCount={length}
          sortOptions={sortOptions}
          hasHorizontalScroll
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
          customSideElements={
            <Select
              mode="multiple"
              allowClear
              style={{ flex: 1, minWidth: '225px' }}
              placeholder={i18n.t(
                'deviceSecurity.deviceSecuritySummary.filterPlaceholder'
              )}
              defaultValue={[]}
              onChange={setDeviceFilter}
              options={[
                {
                  value: DEVICE_TYPES.DESKTOP,
                  label: i18n.t('deviceSecurity.deviceSecuritySummary.desktop'),
                },
                {
                  value: DEVICE_TYPES.SERVER,
                  label: i18n.t('deviceSecurity.deviceSecuritySummary.server'),
                },
                {
                  value: DEVICE_TYPES.MOBILE,
                  label: i18n.t('deviceSecurity.deviceSecuritySummary.mobile'),
                },
              ]}
            />
          }
        />
      </SectionCard>
    </SCDevicesSummary>
  );
};

export default DevicesSummary;
