import axios from 'axios';
import firebase from '../../firebase';

export const removeFirestampAttributes = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) return;
    if (obj[key].seconds) {
      delete obj[key];
    }
  });
};

export async function save(clientId, whitelisting) {
  firebase.firestore().collection('clients').doc(clientId).update({
    whitelisting,
  });
}

export function updateBillingAddress(clientId, billingAddress) {
  if (!clientId || !billingAddress) return false;
  return axios
    .put(`/clients/${clientId}/billingAddress`, {
      newBillingAddress: billingAddress,
    })
    .then((res) => true)
    .catch((error) => false);
}

export async function addDomain(client, newDomain) {
  const newClient = { ...client };
  newClient.monitoredDomains.push(newDomain);
  firebase.firestore().collection('clients').doc(client.id).update({
    monitoredDomains: newClient.monitoredDomains,
  });
}

export async function updateDomain(client, newDomain, position) {
  const newClient = { ...client };
  newClient.monitoredDomains[position] = newDomain;
  firebase.firestore().collection('clients').doc(client.id).update({
    monitoredDomains: newClient.monitoredDomains,
  });
}

export async function updateCampaigns(clientId) {
  if (!clientId) return false;
  return axios
    .put(`/campaign/${clientId}`)
    .then((res) => true)
    .catch((error) => false);
}

export async function updateCampaign(clientId, campaignId) {
  if (!clientId || !campaignId) return false;
  return axios
    .put(`/campaign/${clientId}/${campaignId}`)
    .then((res) => true)
    .catch((error) => false);
}

async function testCampaign(newCampaign) {
  if (!newCampaign) return false;
  return axios
    .post('/campaign', newCampaign)
    .then((res) => true)
    .catch((error) => false);
}

async function addCampaign(clientId, newCampaign) {
  if (!clientId || !newCampaign) return false;
  return axios
    .post(`/campaign/${clientId}`, newCampaign)
    .then((res) => res.data)
    .catch((error) => false);
}

export async function deleteCampaign(client, campaign_id) {
  if (!campaign_id) {
    console.log(
      `campaign_id of campaign to delete is incorrect (${campaign_id})`
    );
    return false;
  }
  const saved = await axios.delete(`/campaign/${client.id}/${campaign_id}`);
  return saved;
}

export async function createCampaign(campaign, saveCampaign, client, user) {
  if (!campaign || !campaign.campaign_type) return;

  // Create campaign object
  const newCampaign = {
    campaign_type: campaign.campaign_type,
    campaign_name: campaign.campaign_name,
    deferred: campaign.deferred,
    destinations: [],
    company: client.company.name,
    whitelisting: client.whitelisting,
    owner: user.email,
    // items passed in the customization object
    // will be used to template the phishing email html
    // keys in this object need to match whatever is in the template
    // using python templates so "company" value will subsitute "$company"
    // TODO: eventually use user input for this?
    customization: {
      company: client.company.name,
      email: user.email, // TODO: or client.email? likely user.email is the new one
      // endDate: some campaigns may require "this offer ends by $endDate"
    },
  };

  if (campaign.campaign_targets === 'All') {
    newCampaign.destinations = client.emailList.filter(
      (dest) => dest.email !== client.email && dest.include_in_phishing
    );
  }

  if (campaign.campaign_targets === 'Selected') {
    newCampaign.destinations = campaign.campaign_recipients;
    if (campaign.campaign_launch_date) {
      newCampaign.start_date = campaign.campaign_launch_date;
    }
  }

  // If test campaign or user hasn't set up users
  if (!newCampaign.destinations || newCampaign.destinations.length === 0) {
    newCampaign.destinations = [
      {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
      },
    ];
  }

  // Call the api to create the campaign
  if (!saveCampaign) {
    const success = await testCampaign(newCampaign);
    return null;
  }
  newCampaign.destinations = newCampaign.destinations.map((item) => ({
    ...item,
    first_name: item.firstName,
    last_name: item.lastName,
  }));
  const createdCampaign = await addCampaign(client.id, newCampaign);

  console.log(createdCampaign);

  if (!createdCampaign) {
    console.log('error while creating');
    return null;
  }
  return true;
}
export default false;
