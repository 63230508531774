import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import SCDonutChartCard from './DonutChart.style';
import Icon from '../../atoms/Icon/Icon';

const DonutChart = ({ subtitle, chartOptions }) => {
  return (
    <SCDonutChartCard>
      <div className="subtitle">
        <Icon iconName={faCircleExclamation} color="var(--red)" size="medium" />
        <p>
          <b>{subtitle}</b>
        </p>
      </div>

      <ReactApexChart
        type="donut"
        series={chartOptions.series}
        options={chartOptions.options}
        width="450"
      />
    </SCDonutChartCard>
  );
};

export default DonutChart;
