import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMailContentCard from './MailContentCard.style';

const MailContentCard = ({ mailInfo }) => {
  const i18n = useTranslation();

  const [formattedContent, setFormattedContent] = useState();

  useEffect(() => {
    if (mailInfo && mailInfo.body) {
      const insideHtml = mailInfo.body
        .split('<html>')
        .pop()
        .split('</html>')[0];

      const formatted = `<html>${insideHtml}</html>`;

      setFormattedContent(formatted);
    }
  }, [mailInfo]);

  return (
    <SCMailContentCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={i18n.t('mailbox-security.mail.content.title')}>
        <div className="MailContentCard_MainContainer">
          {formattedContent && (
            <textarea rows="16" readOnly>
              {formattedContent}
            </textarea>
          )}
        </div>
      </SectionCard>
    </SCMailContentCard>
  );
};

export default MailContentCard;
