import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Skeleton } from 'antd';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import SkeletonButton from 'antd/es/skeleton/Button';
import Label from '../../components/atoms/Label/Label';
import { getDateFormat } from '../../utils/format/dataFormat';
import {
  getSecurityColor,
  getSecurityText,
} from '../../utils/functions/securitySeverity';
import { useTranslation } from '../../i18n';
import SCPartnerClients from './PartnerClients.style';
import { SUBSCRIPTIONS_PRODUCTS } from '../../hooks/subscriptionType/useSubscriptionType';
import usePartnerClients from '../../hooks/usePartnerClients/usePartnerClients';
import PartnerClientControlPanelSummary from '../../components/organisms/PartnerClientControlPanelSummary/PartnerClientControlPanelSummary';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import Input from '../../components/atoms/Input/Input';
import getCompanyIDExample from '../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { isNonCountryEnvironment } from '../../utils/locale';

export const PARTNER_CLIENT_SUBSCRIPTIONS_STATUS = {
  expired: 'EXPIRED',
  paying: 'ACTIVE',
  trial: 'TRIAL',
};

export const getSubscriptionStatus = (partnerClientStatus) => {
  if (
    partnerClientStatus.active < new Date() ||
    partnerClientStatus.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.DEFAULT
  ) {
    return PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;
  }

  return (
    PARTNER_CLIENT_SUBSCRIPTIONS_STATUS[
      partnerClientStatus.serviceLevelIDs[0]
    ] ?? PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
  );
};

const PartnerClients = () => {
  const navigate = useNavigate();
  const i18n = useTranslation();
  const { partnerClients, loading } = usePartnerClients();
  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('id');
  const [inputFilter, setInputFilter] = useState();

  /**
   * Siempre debe haber un clientId. Cuando se renderice la página debemos mostrar el panel
   * del primer clientId de la tabla.
   * 'clientId' debe estar en las dependencias por si, estando ya en la vista 'Clientes', se
   * pulsa el botón 'Clientes' del menú de navegación.
   */
  useEffect(() => {
    if (partnerClients.length > 0 && !clientId) {
      navigate(`?id=${partnerClients[0].id}`);
    }
  }, [partnerClients.length, clientId]);

  const getValueLabel = (score) => {
    if (!score) {
      return i18n.t('websiteSecurity.unknown');
    }

    const formattedScore = Math.round(score * 10) / 10;
    const securityText = getSecurityText(score);

    return `${i18n.t(`websiteSecurity.${securityText}`)} (${formattedScore})`;
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  const columns = [
    {
      title: i18n.t('partner.table.headers.name'),
      key: 'companyName',
      dataIndex: 'companyName',
      sortDirections,
      sorter: (
        { companyName: aCompanyName },
        { companyName: bCompanyName }
      ) => {
        return aCompanyName.localeCompare(bCompanyName);
      },
      render: (text) => text || '-',
    },
    // Si es un entorno de país, se introduce la columna 'companyCif'
    ...(!isNonCountryEnvironment
      ? [
          {
            title: getCompanyIDExample().name,
            key: 'companyCif',
            dataIndex: 'companyCif',
            sortDirections,
            sorter: (
              { companyCif: aCompanyCif },
              { companyCif: bCompanyCif }
            ) => {
              return aCompanyCif.localeCompare(bCompanyCif);
            },
            render: (text) => text || '-',
          },
        ]
      : []),
    {
      title: i18n.t('partner.table.headers.score'),
      key: 'score',
      dataIndex: 'score',
      width: 132,
      sortDirections,
      sorter: (a, b) => a.score - b.score,
      render: (text) => (
        <Label value={getValueLabel(text)} color={getSecurityColor(text)} />
      ),
    },
    {
      title: i18n.t('partner.table.headers.subscriptionStatus'),
      key: 'subscriptionStatus',
      dataIndex: 'subscriptionStatus',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        return i18n
          .t(`partner.table.body.${subscriptionStatusAText}`)
          .localeCompare(
            i18n.t(`partner.table.body.${subscriptionStatusBText}`)
          );
      },
      render: (text) => {
        const subscriptionStatus = getSubscriptionStatus(text);

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return (
          <p
            style={{
              fontSize: '14px',
              color: isSubscriptionExpired && 'var(--red)',
              fontWeight: isSubscriptionExpired && '600',
            }}>
            {i18n.t(`partner.table.body.${subscriptionStatus}`)}
          </p>
        );
      },
    },
    {
      title: i18n.t('partner.table.headers.licenses'),
      key: 'licenses',
      dataIndex: 'licenses',
      sortDirections,
      sorter: (a, b) => a.licenses - b.licenses,
    },
    {
      title: i18n.t('partner.table.headers.expiration'),
      key: 'expiredAt',
      dataIndex: 'expiredAt',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        const subscriptionStatusATimestamp =
          subscriptionStatusAText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusA.active;
        const subscriptionStatusBTimestamp =
          subscriptionStatusBText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusB.active;

        return subscriptionStatusATimestamp - subscriptionStatusBTimestamp;
      },
      render: (text, record) => {
        const subscriptionStatus = getSubscriptionStatus(
          record.subscriptionStatus
        );

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return !isSubscriptionExpired ? getDateFormat(new Date(text)) : '-';
      },
    },
  ];

  const filteredPartnerClients = inputFilter
    ? partnerClients.filter(({ company }) =>
        [company.name, company.cif].some((item) =>
          item.toLowerCase().includes(inputFilter.toLowerCase())
        )
      )
    : partnerClients;

  const data = filteredPartnerClients.map(
    ({ id, company, scoring, status }) => ({
      id,
      companyName: company.name,
      companyCif: company.cif,
      score: scoring.lastValue,
      subscriptionStatus: status,
      licenses: status.nbLicenses,
      expiredAt: status.active,
    })
  );

  const handleInputFilter = (inputValue) => {
    setInputFilter(inputValue);
  };

  const getRowClassName = ({ id }) => {
    return `${id === clientId ? 'selected-row' : ''} cursor-pointer`;
  };

  const displayControlPanel = loading || partnerClients.length > 0;

  return (
    <SCPartnerClients showFull={!displayControlPanel}>
      <SectionCard className="table-container">
        <div className="input-container">
          {loading ? (
            <SkeletonButton style={{ width: '210px' }} />
          ) : (
            <Input
              inputType="text"
              inputPlaceholder={i18n.t('common.search')}
              onChangeValue={handleInputFilter}
              icon={faMagnifyingGlass}
              size="short"
              design="round"
            />
          )}
        </div>

        <Table
          pagination={false}
          scroll={{ y: 560, x: 950 }}
          onRow={(record) => {
            return {
              onClick: () => navigate(`?id=${record.id}`),
            };
          }}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          locale={{
            ...(loading && {
              emptyText: <Skeleton active />,
            }),
          }}
        />
      </SectionCard>

      {displayControlPanel && (
        <PartnerClientControlPanelSummary
          clientId={clientId}
          className="partner-clients-summary"
        />
      )}
    </SCPartnerClients>
  );
};

export default PartnerClients;
