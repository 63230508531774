import { combineReducers } from 'redux';
import userReducer from './userReducer';
import authReducer from './authReducer';
import firebaseReducer from './firebaseReducer';
import clientReducer from './clientReducer';
import monitoredDomainsReducer from './monitoredDomainsReducer';
import monitoredEmailsReducer from './monitoredEmailsReducer';
import popUpReducer from './popUpReducer';
import monitoredSuppliersReducer from './monitoredSuppliersReducer';
import dnsRecordReducer from './dnsRecordReducer';
import teamMembersReducer from './teamMembersReducer';
import myUserPreferencesReducer from './myUserPreferencesReducer';
import campaignsReducer from './campaignsReducer';
import phishingTrainingsReducer from './phishingTrainingsReducer';
import subscriptionsReducer from './subscriptionsReducer';
import protectedDevicesReducer from './protectedDevicesReducer';
import quarantineEmailsReducer from './quarantineEmailsReducer';
import paymentMethodsReducer from './paymentMethodsReducer';
import quarantineTableFiltersReducer from './quarantineTableFiltersReducer';
import historyPersistenceReducer from './historyPersistenceReducer';
import paymentsReducer from './paymentsReducer';
import managedThreatCountersReducer from './managedThreatCountersReducer';
import impersonationsReducer from './impersonationsReducer';
import emailProtectionTutorialReducer from './emailProtectionTutorialReducer';
import partnerConfigReducer from './partnerConfigReducer';

export default combineReducers({
  auth: authReducer,
  campaigns: campaignsReducer,
  dnsRecord: dnsRecordReducer,
  firebase: firebaseReducer,
  client: clientReducer,
  historyPersistence: historyPersistenceReducer,
  managedThreatCounters: managedThreatCountersReducer,
  monitoredDomains: monitoredDomainsReducer,
  monitoredEmails: monitoredEmailsReducer,
  monitoredSuppliers: monitoredSuppliersReducer,
  myUserPreferences: myUserPreferencesReducer,
  paymentMethods: paymentMethodsReducer,
  payments: paymentsReducer,
  phishingTrainings: phishingTrainingsReducer,
  popUp: popUpReducer,
  protectedDevices: protectedDevicesReducer,
  quarantineEmails: quarantineEmailsReducer,
  quarantineTableFilters: quarantineTableFiltersReducer,
  subscriptions: subscriptionsReducer,
  teamMembers: teamMembersReducer,
  user: userReducer,
  impersonations: impersonationsReducer,
  emailProtectionTutorial: emailProtectionTutorialReducer,
  partnerConfig: partnerConfigReducer,
});
