import { firestore } from '../../firebase';
import { GET_PROTECTED_DEVICES } from './types';

const setProtectedDevicesListener = (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('protection_tools')
    .where('installationDate', '!=', null)
    .onSnapshot((snapshot) => {
      const clientDevices = [];

      if (!snapshot.empty) {
        snapshot.forEach((element) => {
          const device = element.data();
          clientDevices.push(device);
        });
      }

      dispatch({
        type: GET_PROTECTED_DEVICES,
        payload: [...clientDevices],
      });
    });
};

export default setProtectedDevicesListener;
