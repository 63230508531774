import axios from 'axios';

const sendLicenses = ({ recipients, deferred }) => {
  return axios.post('/device-protection/send', {
    recipients,
    deferred,
  });
};

export default { sendLicenses };
