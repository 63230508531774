import styled from '@emotion/styled';

const SCMailboxSecurityTable = styled.div`
  p {
    font-weight: 400;
  }

  .MailboxSecurityTable_expiration {
    display: flex;
    flex-direction: row;
  }
`;

export default SCMailboxSecurityTable;
