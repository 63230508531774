import {
  GET_QUARANTINETABLEFILTERS,
  SET_QUARANTINETABLEBETWEENDATESFILTER,
  SET_QUARANTINETABLEDESTINATIONFILTER,
  SET_QUARANTINETABLETYPEFILTER,
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_QUARANTINETABLEFILTERS:
      return action.payload || null;
    case SET_QUARANTINETABLETYPEFILTER:
      return {
        ...state,
        type: action.payload,
      };
    case SET_QUARANTINETABLEDESTINATIONFILTER:
      return {
        ...state,
        destinations: action.payload,
      };
    case SET_QUARANTINETABLEBETWEENDATESFILTER:
      return {
        ...state,
        betweenDates: action.payload,
      };
    default:
      return state;
  }
};
