import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import {
  faClockRotateLeft,
  faFile,
  faUserCowboy,
  faUserTie,
  faEnvelope,
} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { useTranslation } from '../../../i18n';
import { getDateFormat } from '../../../utils/format/dataFormat';
import Icon from '../../atoms/Icon/Icon';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMailDetailsCard from './mailDetailsCard.style';

const MailDetailsCard = ({ mailInfo }) => {
  const i18n = useTranslation();
  return (
    <SCMailDetailsCard>
      <SectionCard
        headerIcon={faEnvelopeOpen}
        headerTitle={i18n.t('mailbox-security.mail.details.title')}>
        <div className="MailDetailsCard_MainContainer">
          <div className="MailDetailsCard_Details">
            <div className="MailDetailsCard_InfoContainer">
              <Icon
                iconName={faEnvelope}
                size="semiLarge"
                color="var(--bluish-grey-3)"
              />
              <div className="MailDetailsCard_Info">
                <span>
                  {i18n.t('mailbox-security.mail.details.keys.subject')}
                </span>
                <p>{mailInfo?.subject ?? i18n.t('websiteSecurity.unknown')}</p>
              </div>
            </div>

            <div className="MailDetailsCard_Row">
              <div className="MailDetailsCard_InfoContainer">
                <Icon
                  iconName={faUserCowboy}
                  size="semiLarge"
                  color="var(--bluish-grey-3)"
                />

                <div className="MailDetailsCard_Info">
                  <span>
                    {i18n.t('mailbox-security.mail.details.keys.from')}
                  </span>
                  <p className="fs-exclude">
                    {mailInfo?.from ?? i18n.t('websiteSecurity.unknown')}
                  </p>
                </div>
              </div>

              <div className="MailDetailsCard_InfoContainer">
                <Icon
                  iconName={faUserTie}
                  size="semiLarge"
                  color="var(--bluish-grey-3)"
                />

                <div className="MailDetailsCard_Info">
                  <span>{i18n.t('mailbox-security.mail.details.keys.to')}</span>
                  <p className="fs-exclude">
                    {mailInfo?.rcpts ?? i18n.t('websiteSecurity.unknown')}
                  </p>
                </div>
              </div>
            </div>
            <div className="MailDetailsCard_Row">
              <div className="MailDetailsCard_InfoContainer">
                <Icon
                  iconName={faClockRotateLeft}
                  size="semiLarge"
                  color="var(--bluish-grey-3)"
                />

                <div className="MailDetailsCard_Info">
                  <span>
                    {i18n.t('mailbox-security.mail.details.keys.date')}
                  </span>
                  <p>
                    {getDateFormat(mailInfo?.date?.toDate()) ??
                      i18n.t('websiteSecurity.unknown')}{' '}
                    {i18n.t('mailbox-security.mail.details.keys.hourLabel')}
                    {moment(mailInfo?.date.toDate()).format('HH:mm') ??
                      i18n.t('websiteSecurity.unknown')}
                  </p>
                </div>
              </div>

              <div className="MailDetailsCard_InfoContainer">
                <Icon
                  iconName={faFile}
                  size="semiLarge"
                  color="var(--bluish-grey-3)"
                />

                <div className="MailDetailsCard_Info">
                  <span>
                    {i18n.t('mailbox-security.mail.details.keys.size')}
                  </span>
                  <p>{mailInfo?.size ?? i18n.t('websiteSecurity.unknown')}kB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionCard>
    </SCMailDetailsCard>
  );
};

export default MailDetailsCard;
