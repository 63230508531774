import React from 'react';
import { useSelector } from 'react-redux';
import SCControlPanelSection from './ControlPanelSection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import MySubscriptionCard from '../../components/organisms/PartnerClientMySubscriptionCard/MySubscriptionCard';
import YourSubscriptionCardInfoBanner from '../../components/molecules/YourSubscriptionCardInfoBanner/YourSubscriptionCardInfoBanner';
import LICENSEES from '../../utils/constants/licensees';
import DeviceSecurityCard from '../../components/organisms/DeviceSecurityCard/DeviceSecurityCard';
import BreachNotificationCard from '../../components/organisms/BreachNotificationCard/BreachNotificationCard';
import EmailSecurityCard from '../../components/organisms/EmailSecurityCard/EmailSecurityCard';
import WebsiteSecurityCard from '../../components/organisms/WebsiteSecurityCard/WebsiteSecurityCard';
import DomainImpersonationCard from '../../components/organisms/DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../../components/organisms/SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../../components/organisms/PhishingTrainingCard/PhishingTrainingCard';
import useSubscriptionType from '../../hooks/subscriptionType/useSubscriptionType';
import { hasPaymentAccess } from '../../utils/functions/subscriptions';

const ControlPanelSection = () => {
  const client = useSelector((redux) => redux.client);
  const { currentSubscription } = useSubscriptionType();

  const monitoredDomain = client?.monitoredDomains[0];
  const webSecurityData =
    client?.status?.webSecurity?.protectionScore[monitoredDomain];

  const webSecurity = {
    protectionScore: {
      [monitoredDomain]: {
        ...webSecurityData,
      },
    },
    updatedAt: client?.status?.webSecurity?.updatedAt,
  };

  return (
    <SectionLayout>
      <SCControlPanelSection>
        <div className="section-organism">
          <MySubscriptionCard
            totalLicenses={
              client?.status?.serviceLevel?.limits?.nbLicences ?? 0
            }
            installedLicenses={client?.status?.nbLicensesUsed ?? 0}
            sentLicenses={client?.status?.nbLicensesSent ?? 0}
            currentSubscriptionProduct={client?.status?.serviceLevelIDs[0]}
            currentSubscriptionExpirationDate={client?.status?.active.toDate()}
            currentSubscriptionPeriodicity={currentSubscription?.periodicity}
            subscriptionStartedAt={client?.status?.subscriptionStartedAt.toDate()}
            showLinkToPlans={client.licensee === LICENSEES.santander}
            isHeaderClickable
            {...(hasPaymentAccess() && {
              infoBanner: <YourSubscriptionCardInfoBanner />,
            })}
          />
        </div>

        <div className="section-organism multi-column">
          <DeviceSecurityCard
            data={client?.status?.deviceSecurity}
            isHeaderClickable
            showEmptyCardButton
          />
          <BreachNotificationCard
            data={client?.status?.dataLeaks}
            isHeaderClickable
          />
        </div>

        <div className="section-organism multi-column">
          <EmailSecurityCard
            data={client?.status?.emailSecurity}
            atEmail={client?.atEmail}
            isHeaderClickable
          />
          <WebsiteSecurityCard data={webSecurity} isHeaderClickable />
        </div>

        <div className="section-organism multi-column">
          <DomainImpersonationCard
            monitoredDomainsLength={client?.monitoredDomains?.length}
            data={client?.status?.webSpoofing}
            isHeaderClickable
          />
          <SupplierSecurityCard
            data={client?.status?.suppliersSecurity}
            isHeaderClickable
            showEmptyCardButton
          />
        </div>

        <div className="section-organism half-wide">
          <PhishingTrainingCard
            data={client?.status?.phishingTraining}
            isHeaderClickable
          />
        </div>
      </SCControlPanelSection>
    </SectionLayout>
  );
};

export default ControlPanelSection;
