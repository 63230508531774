import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import useSubscriptionType from '../../hooks/subscriptionType/useSubscriptionType';
import useLicenses from '../../hooks/licenses/useLicenses';
import useDashboard from '../../hooks/useDashboard/useDashboard';
import useSection from '../../hooks/useSection/useSection';
import useMaintainMode from '../../hooks/useMaintainMode/useMaintainMode';
import ApplicationSections from '../../utils/sections/sections';
import { PAYMENT_METHODS } from '../../utils/constants/payments';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Spinner from '../Misc/Spinner';
import MobileMenu from '../organisms/MobileMenu/MobileMenu';
import ApplicationTopMenu from '../organisms/ApplicationTopMenu/ApplicationTopMenu';
import InvalidPaymentMethodBanner from '../organisms/InvalidPaymentMethodBanner/InvalidPaymentMethodBanner';
import MaintenanceBanner from '../organisms/MaintenanceBanner/MaintenanceBanner';
import ContactLegalInformation from '../molecules/ContactLegalInformation/ContactLegalInformation';

const Dashboard = () => {
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);
  const [mobileMenuOpened, setMobileMenu] = useState(false);
  const { lang } = useTranslation();
  const { currentSubscription } = useSubscriptionType();
  const { totalLicenses, sentLicenses, installedLicenses } = useLicenses();
  const { isMaintainMode } = useMaintainMode();
  const { loading, saveSession, updateLastVisit } = useDashboard();
  const { section } = useSection();

  useEffect(() => {
    if (window.FS) {
      window.FS.identify(client.id, {
        displayName: client?.company?.name || 'null',
      });
    }

    if (client) {
      saveSession();
      updateLastVisit();

      if (!client?.company?.website) {
        dispatch(showPopUp('welcomePopUp'));
      }
    }
  }, []);

  useEffect(() => {
    /* eslint-disable */
    if (window.Tawk_API && window.Tawk_API.onLoaded) {
      Tawk_API.setAttributes(
        {
          language: lang,
          name: user?.firstName + ' ' + user?.lastName,
          email: user?.email,
          country: process.env.REACT_APP_ENVIRONMENT,
          userName: user?.firstName + ' ' + user?.lastName,
          userEmail: user?.email,
          userEmailDomain: client?.atEmail,
          companyName: client?.company?.name,
          companyCif: client?.company?.cif,
          companyWebsite: client?.company?.website,
          subscriptionType: currentSubscription?.product,
          subscriptionStartDate:
            currentSubscription?.startTime
              .toDate()
              .toLocaleDateString('es-ES') || '-',
          subscriptionEndDate:
            currentSubscription?.endTime.toDate().toLocaleDateString('es-ES') ||
            '-',
          subscriptionLicenses: totalLicenses,
          subscriptionSentLicenses: sentLicenses,
          subscriptionInstalledLicenses: installedLicenses,
          hash: 'f88628668a083c77dd68e5e43b05f1adf7bcce95e8ed381d3daf8c2f7dc15857',
        },
        function (error) {
          if (error) console.log('Tawk_API.setAttributes error', error);
        }
      );
    }
    /* eslint-enable */
  }, [client, currentSubscription, lang]);

  const closeMenu = () => {
    if (mobileMenuOpened) setMobileMenu(false);
  };

  const hasSubNavbar = () =>
    [].concat(...Object.values(ApplicationSections)).includes(section) ||
    section === 'settings';

  const createSectionClassname = () => {
    let sectionClassName = 'section';
    if (mobileMenuOpened) {
      sectionClassName += ' dataObfuscation';
    }
    if (hasSubNavbar()) {
      sectionClassName += ' has_subnavbar';
    }
    return sectionClassName;
  };

  return (
    <>
      {loading && <Spinner loading={loading} />}
      <div className="clientDashboard">
        <ApplicationTopMenu
          mobileMenuOpened={mobileMenuOpened}
          setMobileMenu={setMobileMenu}
        />
        <MobileMenu
          mobileMenuOpened={mobileMenuOpened}
          setMobileMenu={setMobileMenu}
        />
        <div
          role="button"
          tabIndex="0"
          className={createSectionClassname()}
          onKeyDown={closeMenu}
          onClick={closeMenu}>
          {client?.status.paymentMethod === PAYMENT_METHODS.INVALID && (
            <InvalidPaymentMethodBanner />
          )}
          {isMaintainMode && <MaintenanceBanner />}

          <Outlet />

          <ContactLegalInformation
            color="transparent"
            size="section"
            separator
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
