import React, { useEffect } from 'react';
import {
  faEnvelope,
  faCircleRadiation,
  faClock,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import SCMailboxSecurityTable from './mailboxSecurityTable.style';
import { useTranslation } from '../../../i18n';
import { useQuarantineEmails } from '../../../hooks/useQuarantineEmails/useQuarantineEmails';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import {
  getDateFormat,
  getDateInMs,
  getDifferenceRegardingToday,
} from '../../../utils/format/dataFormat';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Icon from '../../atoms/Icon/Icon';
import Label from '../../atoms/Label/Label';
import {
  SET_QUARANTINETABLEBETWEENDATESFILTER,
  SET_QUARANTINETABLEDESTINATIONFILTER,
  SET_QUARANTINETABLETYPEFILTER,
} from '../../../redux/actions/types';
import useHistoryPersistence from '../../../hooks/historyPersistance/historyPersistance';

const MailboxSecurityTable = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const PageSize = 10;
  const { quarantineEmails, quarantineReasons } = useQuarantineEmails();

  const teamMembers = useSelector((redux) => redux.teamMembers);
  const quarantineTableFilters = useSelector(
    (redux) => redux.quarantineTableFilters
  );
  const { previousRoute } = useHistoryPersistence();

  useEffect(() => {
    if (previousRoute === '/client/mail') {
      if (quarantineTableFilters?.type) {
        setQuarantineType(quarantineTableFilters.type);
      }
      if (quarantineTableFilters?.betweenDates) {
        setQuarantineBetweenDates(quarantineTableFilters?.betweenDates);
      }
      if (quarantineTableFilters?.destinations) {
        setQuarantineDestinations(quarantineTableFilters?.destinations);
      }
    }
  }, [quarantineTableFilters, previousRoute]);

  const getTagsOfQuarantineEmailDestinations = (quarantineEmail) => {
    const destinationsTags = [];
    quarantineEmail.rcpts.forEach((rcpt) => {
      const teamMemberRcpt = teamMembers?.find((teamMember) => {
        return teamMember.email === rcpt;
      });
      if (teamMemberRcpt) {
        destinationsTags.concat(teamMemberRcpt.tags);
      }
    });

    return [...new Set(destinationsTags)];
  };

  const mapperFn = (singleQuarantineEmails) => {
    return {
      Asunto: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{singleQuarantineEmails.subject}</p>}>
            <Icon
              iconName={faEnvelope}
              size="medium"
              color="var(--bluish-grey-2)"
              style={{
                marginRight: '8px',
              }}
            />
            <p>{singleQuarantineEmails.subject}</p>
          </Tooltip>
        ),
        sortValue: singleQuarantineEmails.subject,
      },
      Motivo: {
        type: 'string',
        value: i18n.t(
          `mailbox-security.mail.table.content.reasons.${singleQuarantineEmails.type}`
        ),
        sortValue: i18n.t(
          `mailbox-security.mail.table.content.reasons.${singleQuarantineEmails.type}`
        ),
      },
      Destinatario: {
        type: 'component',
        value: (
          <Label value={singleQuarantineEmails.rcpts.length} color="default" />
        ),
        sortValue: singleQuarantineEmails.rcpts.length,
      },
      Recepcion: {
        type: 'string',
        value:
          getDateFormat(singleQuarantineEmails.date.toDate()) ??
          i18n.t('websiteSecurity.unknown'),
        sortValue: getDateInMs(singleQuarantineEmails.date.toDate()),
      },
      Expiración: {
        type: 'string',
        value: (
          <div className="MailboxSecurityTable_expiration">
            <Icon
              iconName={faClock}
              size="medium"
              color="var(--bluish-grey-2)"
              style={{
                marginRight: '8px',
              }}
            />
            <p>
              {getDifferenceRegardingToday(
                singleQuarantineEmails?.expiryDate?.toDate()
              )}{' '}
              {i18n.t('websiteSecurity.days')}
            </p>
          </div>
        ),
        sortValue: getDifferenceRegardingToday(
          singleQuarantineEmails?.expiryDate?.toDate()
        ),
      },
      onClick: () => navigate(`/client/mail?id=${singleQuarantineEmails.guid}`),
    };
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('deviceSecurity.selectEmailsModal.name'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const setQuarantineDestinationsWithPersistance = (destinationsFilter) => {
    dispatch({
      type: SET_QUARANTINETABLEDESTINATIONFILTER,
      payload: destinationsFilter,
    });
    setQuarantineDestinations(destinationsFilter);
  };
  const setQuarantineTypeWithPersistance = (typeFilter) => {
    dispatch({
      type: SET_QUARANTINETABLETYPEFILTER,
      payload: typeFilter,
    });
    setQuarantineType(typeFilter);
  };
  const setQuarantineBetweenDatesWithPersistance = (betweenDatesFilter) => {
    dispatch({
      type: SET_QUARANTINETABLEBETWEENDATESFILTER,
      payload: betweenDatesFilter,
    });
    setQuarantineBetweenDates(betweenDatesFilter);
  };

  const {
    currentPage,
    setQuarantineBetweenDates,
    setQuarantineDestinations,
    setQuarantineType,
    quarantineType,
    quarantineBetweenDates,
    quarantineDestinations,
    setPage,
    setSort,
    rows,
    length,
  } = useFilterTable(
    PageSize,
    mapperFn,
    quarantineEmails?.map((quarantineEmail) => {
      return {
        ...quarantineEmail,
        translatedType: i18n.t(
          `mailbox-security.mail.table.content.reasons.${quarantineEmail.type}`
        ),
        tags: getTagsOfQuarantineEmailDestinations(quarantineEmail),
      };
    }),
    ['subject', 'translatedType', 'rcpts'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t('mailbox-security.mail.table.headers.subject'),
      options: {
        stickyOnScroll: true,
        stickyOffset: 0,
        stickyWidth: 200,
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t('mailbox-security.mail.table.headers.reason'),
      options: {
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t('mailbox-security.mail.table.headers.receiver'),
    },
    { name: i18n.t('mailbox-security.mail.table.headers.reception') },
    { name: i18n.t('mailbox-security.mail.table.headers.expiration') },
  ];

  return (
    <SCMailboxSecurityTable>
      <SectionCard
        headerIcon={faCircleRadiation}
        hasOverflow
        headerTitle={`${i18n.t(
          'mailbox-security.mail.table.title'
        )} (${length})`}>
        <TableContainer
          columns={columns}
          emptyMessage={i18n.t('mailbox-security.mail.table.empty')}
          filterByQuarantineBetweenDates={
            setQuarantineBetweenDatesWithPersistance
          }
          filterByQuarantineDestinations={
            setQuarantineDestinationsWithPersistance
          }
          filterByQuarantineType={setQuarantineTypeWithPersistance}
          selectedFilterByQuarantineType={quarantineType}
          selectedFilterByQuarantineBetweenDates={quarantineBetweenDates}
          selectedFilterByQuarantineDestinations={quarantineDestinations}
          quarantineTypes={[
            {
              label: i18n.t(`quarantineTypeFiltering.values.default`),
              value: '',
            },
            ...quarantineReasons.map((quarantineReason) => {
              return {
                label: i18n.t(
                  `mailbox-security.mail.table.content.reasons.${quarantineReason}`
                ),
                value: quarantineReason,
              };
            }),
          ]}
          rows={rows}
          hasRowIcon
          hasHorizontalScroll
          onPageChange={(page) => setPage(page)}
          totalCount={length}
          sortOptions={sortOptions}
          currentPage={currentPage}
          pageSize={PageSize}
        />
      </SectionCard>
    </SCMailboxSecurityTable>
  );
};

export default MailboxSecurityTable;
