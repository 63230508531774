import styled from '@emotion/styled';

const SCDonutChartCard = styled.div`
  height: 100%;

  .subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    & svg {
      margin-right: 10px;
    }

    & p {
      margin: 0;
      font-size: 16px;
    }

    & b {
      color: var(--red);
    }
  }
`;

export default SCDonutChartCard;
