const baseColumnChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      click: undefined,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'darken',
        value: 1,
      },
    },
  },
  fill: {
    type: 'solid',
    colors: [],
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: '10%',
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter: () => {},
    },
  },
  xaxis: {
    categories: [],
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      enabled: false,
      formatter: () => {},
    },
  },
  grid: {
    show: true,
    borderColor: '#F6F6F6',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  toolbar: {
    show: false,
  },
  tooltip: {
    enabled: true,
  },

  title: {
    text: '',
    floating: true,
    offsetY: 330,
    align: 'center',
    style: {
      color: '#444',
    },
  },
};

export default baseColumnChartOptions;
