import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import { useQuarantineEmails } from '../../../hooks/useQuarantineEmails/useQuarantineEmails';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ReleaseQuarantineEmail_Text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    p {
      font-size: 14px;
      color: var(--black);
      font-weight: 400;
    }
  }
`;

const ReleaseQuarantineEmail = (props) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const { releaseQuarantineEmail, quarantineEmails } = useQuarantineEmails();

  const dispatch = useDispatch();
  const { emailInfo } = props;

  useEffect(() => {
    if (quarantineEmails) {
      const auxMailInfo = quarantineEmails.find((quarantineEmail) => {
        return quarantineEmail.guid === emailInfo.guid;
      });

      if (!auxMailInfo) {
        dispatch(showPopUp(null));
        navigate('/client/mailbox-security');
      }
    }
  }, [quarantineEmails]);

  console.log({ emailInfo });

  const release = () => {
    releaseQuarantineEmail({ emailId: emailInfo.id })
      .then((resp) => {})
      .catch((e) => {
        dispatch(showPopUp(null));
        Swal.fire(i18n.t('common.error'), i18n.t('common.errorTryLater'));
      });
  };

  const cancel = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <Wrapper>
        <h1>{i18n.t('mailbox-security.mail.releasePopUp.header')}</h1>
        <div className="modal-card-content ReleaseQuarantineEmail_Text">
          <p> {i18n.t('mailbox-security.mail.releasePopUp.text_one')}</p>
          <p> {i18n.t('mailbox-security.mail.releasePopUp.text_two')}</p>
        </div>
        <div className="modal-card-buttons">
          <Button
            color="bluishGrey"
            size="full"
            type="button"
            text={i18n.t('mailbox-security.mail.releasePopUp.actions.release')}
            onClick={() => {
              release();
            }}
          />

          <Button
            color="white"
            size="full"
            type="button"
            text={i18n.t('mailbox-security.mail.releasePopUp.actions.cancel')}
            onClick={() => {
              cancel();
            }}
          />
        </div>
      </Wrapper>
    </ModalLayout>
  );
};

export default ReleaseQuarantineEmail;
