import {
  faLaptopMobile,
  faShieldPlus,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import { getDonutChartOptions } from '../../../utils/chartFactory/chartFactory';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import DonutChart from '../DonutChart/DonutChart';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import LastUpdateText from '../../atoms/LastUpdateText/LastUpdateText';
import { parseDate } from '../../../utils/format/dataFormat';
import SCDeviceSecurityCard from './DeviceSecurityCard.style';

const DeviceSecurityCard = ({
  data = {},
  isHeaderClickable,
  showEmptyCardButton,
  loading = false,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const {
    fullProtectedDevices = 0,
    incompleteProtectedDevices = 0,
    unprotectedDevices = 0,
    updatedAt,
  } = data;

  const CHART_OPTIONS_DEVICE_PROTECTION = {
    series: [
      fullProtectedDevices,
      incompleteProtectedDevices,
      unprotectedDevices,
    ],
    options: getDonutChartOptions({
      labels: [
        i18n.t('controlPanel.deviceProtection.fullProtection'),
        i18n.t('controlPanel.deviceProtection.partialProtection'),
        i18n.t('controlPanel.deviceProtection.noProtection'),
      ],
      colors: ['var(--light-grey)', 'var(--light-red3)', 'var(--red)'],
      dataLabelsColors: [
        'var(--bluish-grey)',
        'var(--light-grey-4)',
        'var(--light-grey-4)',
      ],
    }),
  };

  const headerButtonOnClick = isHeaderClickable
    ? () => navigate('/client/device-security')
    : undefined;

  return (
    <SCDeviceSecurityCard>
      <SectionCard
        headerIcon={faLaptopMobile}
        headerTitle={i18n.t('controlPanel.deviceProtection.title')}
        headerButtonOnClick={headerButtonOnClick}>
        {!loading ? (
          <div className="chart-date-container">
            {fullProtectedDevices !== 0 || incompleteProtectedDevices !== 0 ? (
              <DonutChart
                subtitle={i18n.t('controlPanel.deviceProtection.subtitle', {
                  devices: incompleteProtectedDevices + unprotectedDevices,
                })}
                chartOptions={CHART_OPTIONS_DEVICE_PROTECTION}
              />
            ) : (
              <EmptyCardContent
                text={i18n.t(
                  'controlPanel.deviceProtection.noLicensesInstalled'
                )}
                {...(showEmptyCardButton && {
                  buttonIcon: faShieldPlus,
                  buttonText: i18n.t('deviceSecurity.installProtections'),
                  buttonOnClick: () =>
                    navigate('/client/device-security/install-protections'),
                })}
              />
            )}

            {updatedAt && <LastUpdateText date={parseDate(updatedAt)} />}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 5 }} />
        )}
      </SectionCard>
    </SCDeviceSecurityCard>
  );
};

export default DeviceSecurityCard;
