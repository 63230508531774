import styled from '@emotion/styled';

const SCWebsiteSecurityVisionFilterCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .scan-container {
    height: 530px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .scan-result-box-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .scan-result-box-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scan-result-row {
    display: flex;
    margin-bottom: -165px;

    > div {
      text-align: center;
      border-radius: 5px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      z-index: 1;
    }

    .mitm-result-wrap {
      margin-left: 187px;
    }

    .clickjacking-result-wrap {
      margin-left: 25px;
    }

    .xss-result-wrap {
      margin-left: 25px;
    }

    .ddos-result-wrap {
      margin-left: 145px;
    }
  }

  .scan-images-row {
    width: 100%;
  }

  .scan-download-container {
    display: flex;
    align-items: center;
  }

  .last-scanned-date {
    margin-left: 20px;
    font-family: var(--font2);
    font-size: 16px;
    color: var(--bluish-grey);
    font-weight: 600;
  }

  .scan-running {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .scan-icon img {
    -webkit-animation: rotating 1s infinite;
    animation: rotating 1s infinite;
    width: 100px;
  }

  .scan-icon {
    margin-top: 40px;
    text-align: center;
  }

  .scan-running-content {
    width: 100%;
  }

  .scan-running-content > h3 {
    font-family: var(--font2);
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--bluish-grey);
  }

  .scan-running-content > p {
    font-family: var(--font2);
    font-size: 16px;
    color: var(--bluish-grey);
  }
`;

export default SCWebsiteSecurityVisionFilterCard;
