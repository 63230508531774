import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import { useTranslation } from '../../../i18n';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';
import Label from '../../atoms/Label/Label';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import TagList from '../../molecules/TagList/TagList';
import SCVisionByEmployeePhishingTableCard from './VisionByEmployeePhishingTableCard.style';

const VisionByEmployeePhishingTableCard = ({
  displayedMembers,
  trainingResults,
}) => {
  const i18n = useTranslation();

  const PAGE_SIZE = 10;

  const mapperFn = (member) => {
    const row = {
      name: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {member?.first_name} {member?.last_name}
              </p>
            }>
            <p>
              {member?.first_name} {member?.last_name}
            </p>
          </Tooltip>
        ),
        sortValue: `${member?.first_name} ${member?.last_name}`,
      },
      sendMails: {
        type: 'component',
        value: <Label value={member.emails_sent || '0'} width="70px" />,
        sortValue: member.emails_sent || '0',
      },
      clicks: {
        type: 'component',
        value: (
          <Label
            value={member.emails_clicked || '0'}
            color={member.emails_clicked && 'red'}
            width="70px"
          />
        ),
        sortValue: member.emails_clicked || '0',
      },
      trainingCompleted: {
        type: 'component',
        value: (
          <Label
            value={
              trainingResults.completed.includes(member.email)
                ? i18n.t('common.yes')
                : i18n.t('common.no')
            }
            color={
              !trainingResults.completed.includes(member.email)
                ? 'red'
                : undefined
            }
            width="70px"
          />
        ),
        sortValue: trainingResults.completed.includes(member.email)
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      },
      lastEmailSent: {
        type: 'string',
        value:
          member.last_email_sent &&
          getDateFormat(member.last_email_sent.toDate()),
        sortValue: getDateInMs(member.last_email_sent.toDate()),
      },
      role: {
        type: 'component',
        value: <TagList teamMember={member} cell />,
      },
    };
    return row;
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('phishingSimulator.teamMembers.name'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const {
    currentPage,
    setSearch,
    setSort,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    displayedMembers,
    ['first_name', 'last_name'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t('phishingSimulator.teamMembers.name'),
      options: {
        stickyOnScroll: true,
        stickyWidth: 200,
        stickyOffset: 0,
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.emailsReceived'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.emailsClicked'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.trainingCompleted'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.lastEmailSent'),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCVisionByEmployeePhishingTableCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'phishingSimulator.generalVisionByEmployee'
        )} (${length})`}>
        <TableContainer
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t('phishingSimulator.teamMembers.emptyMessage')}
          onPageChange={(page) => setPage(page)}
          onChangeFilterTag={setSearchTags}
          hasHorizontalScroll
          totalCount={length}
          currentPage={currentPage}
          sortOptions={sortOptions}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCVisionByEmployeePhishingTableCard>
  );
};

export default VisionByEmployeePhishingTableCard;
