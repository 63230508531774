import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SCMail from './Mail.style';
import { useTranslation } from '../../i18n';
import { useQuarantineEmails } from '../../hooks/useQuarantineEmails/useQuarantineEmails';
import Button from '../../components/atoms/Button/Button';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import MailContentCard from '../../components/organisms/MailContentCard/MailContentCard';
import MailDetailsCard from '../../components/organisms/mailDetailsCard/mailDetailsCard';
import BackButton from '../../components/molecules/BackButton/BackButton';

const Mail = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mailId, setMailId] = useState(undefined);
  const [mailInfo, setmailInfo] = useState(undefined);
  const { quarantineEmails } = useQuarantineEmails();

  const goToMailboxSecuritySection = () => {
    navigate('/client/mailbox-security');
  };

  useEffect(() => {
    if (quarantineEmails && mailId) {
      const auxMailInfo = quarantineEmails.find((quarantineEmail) => {
        return quarantineEmail.guid === mailId;
      });

      if (auxMailInfo) {
        setmailInfo(auxMailInfo);
      } else {
        // goToMailboxSecuritySection();
      }
    }
  }, [mailId, quarantineEmails]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryMailId = urlParams.get('id');
    if (!queryMailId) {
      goToMailboxSecuritySection();
    } else {
      setMailId(queryMailId);
    }
  }, []);

  return (
    <SectionLayout>
      <SCMail>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <MailDetailsCard mailInfo={mailInfo} />
        </div>

        <div className="section-organism">
          <MailContentCard mailInfo={mailInfo} />
        </div>

        <div className="section-organism Mail_Actions">
          <Button
            color="bluishGrey"
            size="large"
            type="button"
            onClick={() => {
              dispatch(showPopUp('removeQuarantineEmail', mailInfo));
            }}
            text={i18n.t('mailbox-security.mail.actions.delete')}
          />
          <Button
            color="white"
            size="large"
            type="button"
            onClick={() => {
              dispatch(showPopUp('reseaseQuarantineEmail', mailInfo));
            }}
            text={i18n.t('mailbox-security.mail.actions.release')}
          />
        </div>
      </SCMail>
    </SectionLayout>
  );
};

export default Mail;
