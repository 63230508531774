import styled from '@emotion/styled';

const SCMailboxSecurityInfo = styled.div`
  .MailboxSecurityInfo_MainContainer {
    display: flex;
    flex-direction: column;
  }

  .MailboxSecurityInfo_ResumeContainer {
    display: flex;
    flex-direction: row;
    gap: 90px;

    .MailboxSecurityInfo_ResumeElem {
      width: 50%;
    }
  }

  .apexcharts-xaxis-texts-g {
    display: none;
  }
  .apexcharts-bar-area:hover {
    fill: var(--red);
  }

  ${({ selectedColumn }) =>
    `.apexcharts-series path:nth-child(${selectedColumn + 1}) { 
      fill: var(--red)
    }`};

  .apexcharts-series path {
    cursor: pointer;
  }
`;

export default SCMailboxSecurityInfo;
