import { firestore } from '../../firebase';
import { GET_QUARANTINE_EMAILS } from './types';

const setQuarantineEmailsListener = (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('quarantine')
    .where('isReleased', '==', false)
    .onSnapshot((snap) => {
      const quarantineEmails = [];

      snap.forEach((quarantineEmail) => {
        if (quarantineEmail.exists) {
          const data = quarantineEmail.data();
          quarantineEmails.push(data);
        }
      });

      dispatch({
        type: GET_QUARANTINE_EMAILS,
        payload: quarantineEmails,
      });
    });
};
export default setQuarantineEmailsListener;
