import axios from 'axios';

export const init = async () => {
  return axios.post('/email-protection/init');
};

export const getMxRecords = async () => {
  return axios.get('/email-protection/mxs');
};

export const release = async (emailId) => {
  return axios.post(`/email-protection/emails/${emailId}/release`);
};

export const remove = async (emailId) => {
  return axios.delete(`/email-protection/emails/${emailId}`);
};
