import axios from 'axios';

/* eslint-disable import/prefer-default-export */
export const requestContact = async (contactPhone, language, name, token) => {
  return axios.post(`/send_data_lead/contact-request`, {
    contactPhone,
    language,
    name,
    token,
  });
};
