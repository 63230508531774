import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SCCallMeWidget from './CallMeWidget.style';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import { isCallMeAvailable } from '../../../utils/locale';

const CallMeWidget = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { pathname } = useLocation();

  return (
    <SCCallMeWidget id="call-me-widget">
      {isCallMeAvailable(pathname) && (
        <div className="text-button-container">
          <span>{i18n.t('callMe.weCallYou')}</span>

          <button type="button" onClick={() => dispatch(showPopUp('callUs'))}>
            <FontAwesomeIcon
              icon={faPhoneVolume}
              color="var(--light-grey-4)"
              size="xl"
            />
          </button>
        </div>
      )}

      <div className="tawk-to-text-button-container">
        <span>{i18n.t('callMe.chat')}</span>

        <div className="tawk-to-space" />
      </div>
    </SCCallMeWidget>
  );
};

export default CallMeWidget;
