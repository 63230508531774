import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Icon from '../../atoms/Icon/Icon';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCLicensesSentByEmployeePopUp = styled.div`
  .text-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  div {
    width: 100%;
  }

  h2 {
    font-weight: 500;
    font-family: var(--font1);
    font-size: 25px;
  }

  h3 {
    font-size: 14px;
  }

  p {
    font-family: var(--font2);
    font-size: 14px;
  }

  > button:first-of-type {
    margin-top: 20px;
  }

  .perfect-paragraph {
    display: flex;
    align-items: center;
    word-break: break-all;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    > p {
      flex: 1 0 90%;
    }
    > div {
      width: 30px;
      height: 30px;
      border-radius: 50vh;
      :hover {
        background-color: var(--light-grey);
      }
    }
  }
`;

const IS_COPIED_DEFAULT_STATE = {
  desktop: false,
  server: false,
};

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
};

const LicensesSentByEmployeePopUp = ({ employeeLicenses }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [isCopied, setIsCopied] = useState(IS_COPIED_DEFAULT_STATE);
  const {
    name,
    email,
    licenses: { sent: sentLicenses = {}, used: usedLicenses = {} } = {},
    setupTokens = {},
  } = employeeLicenses;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(IS_COPIED_DEFAULT_STATE);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isCopied.desktop, isCopied.server]);

  const handleCopy = (tokenToCopy, deviceType) => {
    setIsCopied((prevState) => ({
      ...prevState,
      [deviceType]: true,
    }));

    navigator.clipboard.writeText(tokenToCopy);
  };

  return (
    <ModalLayout>
      <SCLicensesSentByEmployeePopUp>
        <h2>
          {i18n.t('deviceSecurity.selectEmailsModal.sentLicensesTitlePopUp')}
        </h2>

        <div className="modal-card-content text-container">
          <div>
            <h3>{i18n.t('deviceSecurity.selectEmailsModal.nameSurname')}</h3>
            <p>{name}</p>
          </div>

          <div>
            <h3>{i18n.t('deviceSecurity.selectEmailsModal.email')}</h3>
            <p>{email}</p>
          </div>

          {Boolean(sentLicenses.desktop) && (
            <div>
              <h3>
                {i18n.t('deviceSecurity.selectEmailsModal.desktopLicenses')}{' '}
                {`(${i18n.t(
                  'deviceSecurity.selectEmailsModal.sentLicensesbyEmployee',
                  {
                    installed: usedLicenses.desktop,
                    total: sentLicenses.desktop,
                  }
                )})`}
              </h3>
              <div className="perfect-paragraph">
                <p>Token: {setupTokens.desktop}</p>
                <Icon
                  iconName={isCopied.desktop ? faCheck : faCopy}
                  size="medium"
                  color={
                    isCopied.desktop ? 'var(--green)' : 'var(--bluish-grey)'
                  }
                  onClick={
                    !isCopied.desktop
                      ? () => {
                          handleCopy(setupTokens.desktop, DEVICE_TYPES.DESKTOP);
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          )}

          {Boolean(sentLicenses.server) && (
            <div>
              <h3>
                {i18n.t('deviceSecurity.selectEmailsModal.serverLicenses')}{' '}
                {`(${i18n.t(
                  'deviceSecurity.selectEmailsModal.sentLicensesbyEmployee',
                  {
                    installed: usedLicenses.server,
                    total: sentLicenses.server,
                  }
                )})`}
              </h3>
              <div className="perfect-paragraph">
                <p>Token: {setupTokens.server}</p>
                <Icon
                  iconName={isCopied.server ? faCheck : faCopy}
                  size="medium"
                  color={
                    isCopied.server ? 'var(--green)' : 'var(--bluish-grey)'
                  }
                  onClick={
                    !isCopied.server
                      ? () => {
                          handleCopy(setupTokens.server, DEVICE_TYPES.SERVER);
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          )}

          {Boolean(sentLicenses.android) && (
            <div>
              <h3>
                {i18n.t(
                  'deviceSecurity.selectEmailsModal.androidMobileLicenses'
                )}{' '}
                {`(${i18n.t(
                  'deviceSecurity.selectEmailsModal.sentLicensesbyEmployee',
                  {
                    installed: usedLicenses.android,
                    total: sentLicenses.android,
                  }
                )})`}
              </h3>
              <p>
                {i18n.t('deviceSecurity.selectEmailsModal.androidMobileInfo')}
              </p>
            </div>
          )}

          {Boolean(sentLicenses.ios) && (
            <div>
              <h3>
                {i18n.t('deviceSecurity.selectEmailsModal.iosMobileLicenses')}{' '}
                {`(${i18n.t(
                  'deviceSecurity.selectEmailsModal.sentLicensesbyEmployee',
                  {
                    installed: usedLicenses.ios,
                    total: sentLicenses.ios,
                  }
                )})`}
              </h3>
              <p>{i18n.t('deviceSecurity.selectEmailsModal.iosMobileInfo')}</p>
            </div>
          )}
        </div>

        <div className="modal-card-buttons">
          <Button
            size="full"
            color="white"
            text={i18n.t('common.back')}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SCLicensesSentByEmployeePopUp>
    </ModalLayout>
  );
};

export default LicensesSentByEmployeePopUp;
