import React from 'react';
import {
  faArrowUpRightFromSquare,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SectionHeader from '../SectionHeader/SectionHeader';
import { isNonCountryEnvironment } from '../../../utils/locale';
import getCompanyIDExample from '../../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { useTranslation } from '../../../i18n';
import SCPartnerClientControlPanelHeader from './PartnerClientControlPanelHeader.style';
import Button from '../../atoms/Button/Button';
import { isPartnerType } from '../../../utils/functions/partners';
import { PARTNER_TYPES } from '../../../utils/constants/partners';

const PartnerClientControlPanelHeader = ({
  partnerClient,
  loading,
  hasPadding,
}) => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);

  const getHref = () => {
    const partnerClientId = partnerClient?.id;
    const { id: clientId } = client;
    const baseHref = '/client';

    return partnerClientId === clientId
      ? baseHref
      : `${baseHref}?id=${partnerClientId}`;
  };

  const hasConsoleAccess = isPartnerType(PARTNER_TYPES.MSSP);

  return (
    <SCPartnerClientControlPanelHeader hasPadding={hasPadding}>
      <SectionHeader
        icon={faUser}
        // Si es un entorno de país, se añade el companyID
        {...(!isNonCountryEnvironment && {
          title: `${getCompanyIDExample().name}: ${
            partnerClient?.company?.cif
          }`,
        })}
        subtitle={`${i18n.t('common.client')}: ${partnerClient?.company?.name}`}
        loading={loading}
      />
      {hasConsoleAccess && !loading && (
        <a
          href={getHref()}
          target="_blank"
          aria-label="Link to client console"
          rel="noreferrer">
          <Button
            text={i18n.t('partner.consoleAccess')}
            size="fit"
            color="bluishGrey"
            icon={faArrowUpRightFromSquare}
          />
        </a>
      )}
    </SCPartnerClientControlPanelHeader>
  );
};

export default PartnerClientControlPanelHeader;
