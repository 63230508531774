import styled from '@emotion/styled';

const SCDevicesSummary = styled.div`
  > table {
    width: 100%;
  }

  .infoBanner-container {
    margin-bottom: 30px;
  }
`;

export const SCTypeIcons = styled.div`
  display: flex;
  gap: 20px;
`;

export default SCDevicesSummary;
