import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import styled from '@emotion/styled';
import {
  addWebsiteFromMonitor,
  showPopUp,
} from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import { ErrorKey } from '../../../track';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCAddWebsite = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
  }
`;

const AddWebsite = (props) => {
  const i18n = useTranslation();
  const [website, setWebsite] = useState();
  const dispatch = useDispatch();

  const validate = (e) => {
    const reg =
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{1,11}?$/gim;

    e.target.disabled = true;

    if (website.indexOf('/') > -1) {
      ErrorKey('websiteSecurity.add.errorContainsSlash');
      e.target.disabled = false;
      return;
    }

    if (!reg.test(website)) {
      ErrorKey('websiteSecurity.add.errorDomainFormat');
      e.target.disabled = false;
      return;
    }

    props.addWebsiteFromMonitor(website);
  };

  const changeHandlerText = (val) => {
    const replacedVal = `${val
      .replace(/https:\/\//g, '')
      .replace(/http:\/\//g, '')}`
      .trim()
      .toLowerCase();

    setWebsite(replacedVal);
  };

  return (
    <ModalLayout>
      <SCAddWebsite>
        <h1>{i18n.t('websiteSecurity.add.title')}</h1>
        <div className="modal-card-content">
          <Input
            name="website"
            id="website"
            inputType="text"
            size="large"
            inputPlaceholder={i18n.t('websiteSecurity.add.placeholderWebsite')}
            defaultValue={website || ''}
            onChangeValue={(val) => changeHandlerText(val)}
            center
          />
        </div>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t('websiteSecurity.add.validateButton')}
            onClick={(e) => validate(e)}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('websiteSecurity.add.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCAddWebsite>
    </ModalLayout>
  );
};

export default connect(undefined, { addWebsiteFromMonitor })(AddWebsite);
