import React from 'react';
import SCPartnerClientControlPanelSummary from './PartnerClientControlPanelSummary.style';
import usePartnerClient from '../../../hooks/usePartnerClient/usePartnerClient';
import MySubscriptionCard from '../PartnerClientMySubscriptionCard/MySubscriptionCard';
import DeviceSecurityCard from '../DeviceSecurityCard/DeviceSecurityCard';
import BreachNotificationCard from '../BreachNotificationCard/BreachNotificationCard';
import EmailSecurityCard from '../EmailSecurityCard/EmailSecurityCard';
import WebsiteSecurityCard from '../WebsiteSecurityCard/WebsiteSecurityCard';
import DomainImpersonationCard from '../DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../PhishingTrainingCard/PhishingTrainingCard';
import PartnerClientControlPanelHeader from '../../molecules/PartnerClientControlPanelHeader/PartnerClientControlPanelHeader';

const PartnerClientControlPanelSummary = ({ clientId, className }) => {
  const { partnerClient, loading } = usePartnerClient({ clientId });

  return (
    <SCPartnerClientControlPanelSummary className={className}>
      <div className="section-header-container">
        <PartnerClientControlPanelHeader
          partnerClient={partnerClient}
          loading={loading}
          hasPadding
        />
      </div>
      <div className="control-panel-summary-widgets">
        <MySubscriptionCard
          className="subscription-details-card"
          totalLicenses={
            partnerClient?.status?.serviceLevel?.limits?.nbLicences
          }
          installedLicenses={partnerClient?.status?.nbLicensesUsed}
          sentLicenses={partnerClient?.status?.nbLicensesSent}
          currentSubscriptionProduct={partnerClient?.status?.serviceLevelIDs[0]}
          currentSubscriptionExpirationDate={
            new Date(partnerClient?.status?.active)
          }
          subscriptionStartedAt={
            new Date(partnerClient?.status?.subscriptionStartedAt)
          }
          currentSubscriptionPeriodicity={
            partnerClient?.status?.subscriptionPeriodicity
          }
          loading={loading}
          isTwoColumnLayout={false}
        />

        <DeviceSecurityCard
          data={partnerClient?.status?.deviceSecurity}
          loading={loading}
        />

        <BreachNotificationCard
          data={partnerClient?.status?.dataLeaks}
          loading={loading}
        />

        <EmailSecurityCard
          data={partnerClient?.status?.emailSecurity}
          atEmail={partnerClient?.atEmail}
          loading={loading}
        />

        <WebsiteSecurityCard
          data={partnerClient?.status?.webSecurity}
          loading={loading}
        />

        <DomainImpersonationCard
          monitoredDomainsLength={partnerClient?.monitoredDomains?.length}
          data={partnerClient?.status?.webSpoofing}
          loading={loading}
        />

        <SupplierSecurityCard
          data={partnerClient?.status?.suppliersSecurity}
          loading={loading}
        />

        <PhishingTrainingCard
          data={partnerClient?.status?.phishingTraining}
          loading={loading}
        />
      </div>
    </SCPartnerClientControlPanelSummary>
  );
};

export default PartnerClientControlPanelSummary;
