import { useEffect, useState } from 'react';

import * as partnerClientService from '../../services/partnerClients/partnerClients.service';

const usePartnerClient = ({ clientId }) => {
  const [partnerClient, setPartnerClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(async () => {
    if (clientId) {
      setLoading(true);

      partnerClientService
        .getPartnerClient(clientId)
        .then((resp) => setPartnerClient(resp.data?.data))
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientId]);

  // const addPartnerClient = async (
  //   partnerClientName,
  //   partnerClientSector,
  //   partnerClientAtEmail,
  //   partnerClientDomain
  // ) => {
  //   setError(false);
  //   setLoading(true);

  //   const request = {
  //     company: {
  //       name: partnerClientName,
  //       sector: partnerClientSector,
  //     },
  //     atEmail: partnerClientAtEmail,
  //     monitoredDomains: [partnerClientDomain],
  //     partner: client.partner,
  //     licensee: partner.licensee,
  //   };
  //   partnerClientService
  //     .addPartnerClient(request)
  //     .then((response) => {})
  //     .catch(() => {
  //       setError(true);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return {
    loading,
    error,
    partnerClient,
    // addPartnerClient,
  };
};

export default usePartnerClient;
