import React, { useState } from 'react';
import { faUserGear, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faCircle2, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import SCNewPhisingCampaignType from './NewPhisingCampaignEmployeeSelectorCard.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import MiniCard from '../../atoms/MiniCard/MiniCard';
import { TrackButton } from '../../../track';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import TagList from '../../molecules/TagList/TagList';
import Icon from '../../atoms/Icon/Icon';

const NewPhisingCampaignEmployeeSelectorCard = ({
  onChange,
  nSelectedEmails,
}) => {
  const [activeAllEmployees, setActiveAllEmployees] = useState(undefined);

  const i18n = useTranslation();

  const teamMembers = useSelector((redux) => redux.teamMembers);

  const getAllHashedEmails = () => {
    return teamMembers.map((teamMember) => {
      return { ...teamMember, include_in_phishing: true };
    });
  };

  const getHashedEmailsFromRows = (selectedRows) => {
    return selectedRows.map((selectedRow) => {
      return {
        ...teamMembers?.find(
          (teamMember) => teamMember.email === selectedRow.email.value
        ),
        include_in_phishing: true,
      };
    });
  };

  const mapperFn = (item) => {
    return {
      name: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {item.firstName} {item.lastName}
              </p>
            }>
            <p>
              {item.firstName} {item.lastName}
            </p>
          </Tooltip>
        ),
        sortValue: `${item.firstName} ${item.lastName}`,
      },
      email: {
        type: 'string',
        value: item.email,
        sortValue: item.email,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={item} cell />,
      },
    };
  };

  const defaultSortDirection = undefined;
  const sortOptions = {
    activeSortedColumnName: i18n.t(`manageEmailList.name`),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const PAGE_SIZE = 10;

  const {
    currentPage,
    setSearch,
    setSort,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    teamMembers,
    ['firstName', 'lastName', 'email'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t(`manageEmailList.name`),
      options: {
        stickyOnScroll: true,
        stickyWidth: 200,
        stickyOffset: 40,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`manageEmailList.email`),
      options: {
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`manageEmailList.tags`),
    },
  ];

  return (
    <SCNewPhisingCampaignType>
      <SectionCard
        headerIcon={faCircle2}
        headerTitle={i18n.t(
          'phishingSimulator.campaigns.create.selectEmployees'
        )}>
        <div className="NewPhisingCampaignEmployeeSelectorCard_SelectorContainer">
          <MiniCard
            className={`${
              activeAllEmployees === true
                ? 'NewPhisingCampaignEmployeeSelectorCard_STypeSelected'
                : ''
            }`}
            onClick={() => {
              setActiveAllEmployees(true);
              onChange(getAllHashedEmails());
              TrackButton(
                `phishingSimulator.campaigns.create.step4.buttonAllText`
              );
            }}>
            <FontAwesomeIcon icon={faUsers} />
            <div>
              {i18n.t(`phishingSimulator.campaigns.create.step4.buttonAllText`)}
            </div>
          </MiniCard>
          <MiniCard
            className={`${
              activeAllEmployees === false
                ? 'NewPhisingCampaignEmployeeSelectorCard_STypeSelected'
                : ''
            }`}
            onClick={() => {
              setActiveAllEmployees(false);
              TrackButton(
                `phishingSimulator.campaigns.create.step4.buttonSelectedText`
              );
            }}>
            <FontAwesomeIcon icon={faUserGear} />
            <div>
              {i18n.t(
                `phishingSimulator.campaigns.create.step4.buttonSelectedText`
              )}
            </div>
          </MiniCard>
        </div>

        {activeAllEmployees === false && (
          <div className="NewPhisingCampaignEmployeeSelectorCard_SelectorTable">
            <TableContainer
              size="customMid"
              onChangeValue={setSearch}
              columns={columns}
              rows={rows}
              emptyMessage={i18n.t('manageEmailList.emptyMessage')}
              hasRowSelect
              onChangeFilterTag={setSearchTags}
              hasHorizontalScroll
              rowIdentifier="email"
              rowHasBeenSelected={(selectedRows) => {
                onChange(getHashedEmailsFromRows(selectedRows));
              }}
              onPageChange={(page) => setPage(page)}
              totalCount={length}
              currentPage={currentPage}
              sortOptions={sortOptions}
              pageSize={PAGE_SIZE}
              customBottomElements={
                <div className="NewPhisingCampaignEmployeeSelectorCard_SelectedRowActions">
                  <>
                    <div className="NewPhisingCampaignEmployeeSelectorCard_SelectedRowActionsInfo">
                      <Icon
                        iconName={faCircleInfo}
                        color="var(--bluish-grey)"
                      />

                      <p>
                        {nSelectedEmails > 0
                          ? i18n.t(
                              'phishingSimulator.campaigns.create.selectedEmployeesBanner',
                              {
                                numberOfUsers: nSelectedEmails,
                              }
                            )
                          : i18n.t(
                              'phishingSimulator.campaigns.create.selectEmployeesBanner'
                            )}
                      </p>
                    </div>
                  </>
                </div>
              }
            />
          </div>
        )}
      </SectionCard>
    </SCNewPhisingCampaignType>
  );
};

export default NewPhisingCampaignEmployeeSelectorCard;
