import {
  GET_HISTORYPERSISTENCE,
  SET_HISTORYPERSISTENCE,
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_HISTORYPERSISTENCE:
      return action.payload || null;
    case SET_HISTORYPERSISTENCE:
      return {
        currentRoute: action.payload,
        prevRoute: state?.currentRoute,
      };
    default:
      return state;
  }
};
