import React, { useState } from 'react';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/Icon/Icon';
import NavMenu from '../NavMenu/NavMenu';
import NavMenuMobile from '../NavMenuMobile/NavMenuMobile';
import Burger from '../../../img/burger.svg';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo.svg';
import { ReactComponent as SantanderLogo } from '../../../img/cyberguardian/SantanderFullLogo.svg';
import { auth } from '../../../firebase';
import { PUBLIC_HOME } from '../../../utils/constants/urls';
import SCNavbar from './Navbar.style';

const Navbar = ({ hideAuthButtons, showSantanderLogo }) => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleLogo = () => {
    if (auth.currentUser) {
      navigate('/client');
    } else {
      window.location.href = PUBLIC_HOME;
    }
  };

  return (
    <SCNavbar>
      <CyberGuardianLogo className="cyber-guardian-logo" onClick={handleLogo} />
      {showSantanderLogo && <SantanderLogo className="santander-logo" />}

      {!hideAuthButtons && (
        <>
          <div
            role="button"
            tabIndex={0}
            className="burger-menu"
            onKeyDown={() => setShowMobileMenu(!showMobileMenu)}
            onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {!showMobileMenu ? (
              <img src={Burger} alt="burger" />
            ) : (
              <Icon
                size="navIcon"
                iconName={faXmark}
                color="var(--bluish-grey)"
              />
            )}
          </div>

          <NavMenu />

          <NavMenuMobile showMobileMenu={showMobileMenu} />
        </>
      )}
    </SCNavbar>
  );
};

export default Navbar;
