import axios from 'axios';

// export const addPartnerClient = (requestData) => {
//   return axios.post('/broker-adds-client', requestData);
// };

export const getPartnerClients = () => {
  return axios.get(`/clients/partners/clients`);
};

export const getPartnerClient = (clientId) => {
  return axios.get(`/clients/partners/clients/${clientId}`);
};
