const isLeapYear = (year) => {
  // A year is a leap year if it is divisible by 4,
  // except for end-of-century years, which must be divisible by 400
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
};

const getLeapDays = (startDate, endDate) => {
  let startDateCopy = new Date(startDate);
  startDateCopy.setHours(0, 0, 0, 0);

  let endDateCopy = new Date(endDate);
  endDateCopy.setHours(0, 0, 0, 0);

  // Ensure startDate is before endDate
  if (startDateCopy > endDateCopy) {
    [startDateCopy, endDateCopy] = [endDateCopy, startDateCopy];
  }

  // Extract the year from the start and end dates
  const startYear = startDateCopy.getFullYear();
  const endYear = endDateCopy.getFullYear();

  // Count leap days
  let leapDays = 0;
  for (let year = startYear; year <= endYear; year += 1) {
    if (isLeapYear(year)) {
      // Check if February 29th is within the date range in the leap year
      const feb29 = new Date(year, 1, 29); // Month is 0-indexed, 1 is February

      if (feb29 >= startDateCopy && feb29 <= endDateCopy) {
        leapDays += 1;
      }
    }
  }

  return leapDays;
};

export const getDiffYears = (date1, date2) => {
  const milisecondsDay = 1000 * 60 * 60 * 24;
  const daysYear = 365.25;

  const leapDays = getLeapDays(date1, date2);

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const daysDiff = timeDiff / milisecondsDay - leapDays;
  const yearsDiff = daysDiff / daysYear;

  return Math.floor(yearsDiff);
};

export const addYearsToDate = (date, years) => {
  const dateCopy = new Date(date);

  dateCopy.setFullYear(dateCopy.getFullYear() + years);

  return dateCopy;
};

/**
 * Function that subtract months to a given date and return a copy of the date
 */
export const subtractMonthsToDate = (date, months) => {
  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() - months);

  return dateCopy;
};

export const hasPassedMinutes = (timestamp, minutes) => {
  if (!timestamp || !minutes) {
    return false;
  }
  const timestampDate = timestamp.toDate();
  const now = new Date();

  const diffMs = now - timestampDate;
  const diffMins = Math.floor(diffMs / 1000 / 60);

  return diffMins >= minutes;
};
