import React from 'react';
import {
  faSquareSliders,
  faMessagesQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SCManageSubscription from './ManageSubscription.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import {
  addYearsToDate,
  getDiffYears,
  subtractMonthsToDate,
} from '../../../utils/functions/date';
import { getDateFormat } from '../../../utils/format/dataFormat';

const ManageSubscription = () => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);

  const subscriptionExpirationDate = client?.status?.active.toDate();
  const subscriptionStartedAt = client?.status?.subscriptionStartedAt.toDate();

  /**
   * Próxima renovación = (Fecha próxima factura - fecha inicio suscripción) + 1
   */
  const subscriptionDurationYears = getDiffYears(
    subscriptionExpirationDate,
    subscriptionStartedAt
  );
  const renewalDate = addYearsToDate(
    subscriptionStartedAt,
    subscriptionDurationYears + 1
  );
  const finalDate = subtractMonthsToDate(renewalDate, 1);

  return (
    <SCManageSubscription>
      <SectionCard
        headerIcon={faSquareSliders}
        headerTitle={i18n.t('profile.manageSubscription.title')}>
        <p>
          {i18n.t('profile.manageSubscription.actionsText', {
            finalDate: getDateFormat(finalDate),
          })}
        </p>

        <div className="button-container">
          <Button
            color="white"
            size="mid"
            icon={faMessagesQuestion}
            text={i18n.t('common.contactSupport')}
            onClick={() => {
              Tawk_API.toggle();
            }}
          />
        </div>
      </SectionCard>
    </SCManageSubscription>
  );
};

export default ManageSubscription;
