import styled from '@emotion/styled';

const SCDeviceSecurityCard = styled.div`
  .chart-date-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
  }
`;

export default SCDeviceSecurityCard;
