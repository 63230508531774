import React from 'react';
import {
  faChevronRight,
  faCircleCheck,
  faFileArrowDown,
  faList,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Input from '../../atoms/Input/Input';
import SCActionTable from './ActionTable.style';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import Icon from '../../atoms/Icon/Icon';
import { useTranslation } from '../../../i18n';
import { TrackButton } from '../../../track';
import Button from '../../atoms/Button/Button';
import ActionsEmptyMessage from '../../molecules/ActionsEmptyMessage/ActionsEmptyMessage';
import EmptyMessage from '../../molecules/EmptyMessage/EmptyMessage';

const ActionTable = ({
  actions,
  title,
  subtitle = undefined,
  completed,
  onFilter,
  actionSearch,
  minified,
  headerIcon = faList,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  return (
    <SCActionTable>
      <SectionCard
        headerIcon={headerIcon}
        headerTitle={title}
        headerSubtitle={subtitle}>
        {actions.length !== 0 || actionSearch ? (
          <>
            {!minified && (
              <div className="inputContainer">
                <Input
                  inputType="text"
                  size="short"
                  design="round"
                  icon={faMagnifyingGlass}
                  inputPlaceholder={i18n.t('common.search')}
                  defaultValue=""
                  onChangeValue={(val) => {
                    onFilter(val);
                  }}
                />
              </div>
            )}

            {actions.length !== 0 ? (
              <>
                {actions &&
                  actions.map((action) => (
                    <div
                      className="actionRow"
                      key={action.type}
                      onClick={() => {
                        navigate(`/client/action?actionType=${action.type}`);
                        TrackButton('action-center.startAction', action.type);
                      }}>
                      <div className="actionRowLeft">
                        <Icon
                          size="medium"
                          iconName={faCircleCheck}
                          color={
                            completed ? 'var(--green)' : 'var(--bluish-grey-2)'
                          }
                        />
                        {!completed && (
                          <div className="actionRowProgress">
                            <ProgressBar
                              hideXAxis
                              height="5px"
                              backgroundColor="var(--bluish-grey-3)"
                              completed={action.passCount}
                              total={action.totalCount}
                            />
                          </div>
                        )}
                        <p>
                          {i18n.t(`action-center.actions.${action.type}.title`)}
                        </p>
                      </div>
                      <Icon
                        size="medium"
                        iconName={faChevronRight}
                        color="#798897"
                      />
                    </div>
                  ))}

                {minified && (
                  <div className="seeMoreAction">
                    <Button
                      onClick={() => {
                        navigate('/client/actions');
                      }}
                      icon={faFileArrowDown}
                      text={i18n.t('action-center.viewAllActions')}
                      size="mid"
                      color="white"
                    />
                  </div>
                )}
              </>
            ) : (
              <EmptyMessage>
                {completed
                  ? i18n.t('action-center.searchCompletedEmptyMessage')
                  : i18n.t('action-center.searchPendingEmptyMessage')}
              </EmptyMessage>
            )}
          </>
        ) : (
          <ActionsEmptyMessage
            title={
              completed
                ? i18n.t('action-center.titleEmptyMessageCompletedActions')
                : i18n.t('action-center.titleEmptyMessagePendingActions')
            }
          />
        )}
      </SectionCard>
    </SCActionTable>
  );
};
export default ActionTable;
