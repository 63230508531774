import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import RemoveEmail from './RemoveEmail';
import AddWebsite from './AddWebsite';
import RemoveWebsite from './RemoveWebsite';
import RemoveCampaign from './RemoveCampaign';
import TextWithoutButtons from './TextWithoutButtons';
import AddUserInEmailList from './AddUserInEmailList';
import RemoveSupplier from './RemoveSupplier';
import AddSupplier from './AddSupplier';
import Tutorial from './Tutorial';
import RequirePayment from './RequirePayment';
// import Modal from '../../../organisms/Modal/Modal';
import SentEmailToModal from './SentEmailTo';
import WebsiteSecurityPopup from './WebsiteSecurityPopup';
import NotificationsPopUp from './Notification';
import BreachesNotifyAffected from './BreachesNotifyAffected';
import AddCSVUserInEmailList from './AddCSVUserInEmailList';
import SendProtectionsEmails from './SendProtectionsEmails';
import WelcomePopup from '../WelcomePopup/WelcomePopup';
import ReleaseQuarantineEmail from './ReseaseQuarantineEmail';
import RemoveQuarantineEmail from './RemoveQuarantineEmail';
import MaxUsersInEmailListPopUp from './MaxUsersInEmailList';
import TagPicker from './TagPicker';
import RemoveEmails from './RemoveEmails';
import LicensesSentByEmployeePopUp from './LicensesSentByEmployeePopUp';
import ChangePassword from './ChangePassword';
import WhatIncludesMyPlan from './WhatIncludesMyPlan';
import CheckPhisingReceptionEmail from './CheckPhisingReceptionEmail';
import MaxPhishingCampaignsPopUp from './MaxPhishingCampaignsPopUp';
import MaxDomainsPopUp from './MaxDomainsPopUp';
import StripeEditCard from '../../molecules/StripeEditCard/StripeEditCard';
import AddBillingInformation from './AddBillingInformation';
import CallMePopup from './CallMePopup';
import EmailVerificationExpired from './EmailVerificationExpired';
import PaymentDetails from './PaymentDetails';

const PopUp = () => {
  const INITIAL_STATES = {
    popUpHasCloseOnBackdropClick: true,
    customWidth: '420px',
    tawkToAvailable: false,
  };

  const popUp = useSelector((state) => state.popUp);
  const dispatch = useDispatch();

  const [activePopUp, setActivePopUp] = useState(null);
  const [tawkToAvailable, setTawkToAvailable] = useState(
    INITIAL_STATES.tawkToAvailable
  );
  const [popUpHasCloseOnBackdropClick, setPopUpHasCloseOnBackdropClick] =
    useState(INITIAL_STATES.popUpHasCloseOnBackdropClick);

  const [customWidth, setCustomWidth] = useState(INITIAL_STATES.customWidth);

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  useEffect(() => {
    setPopUpHasCloseOnBackdropClick(
      INITIAL_STATES.popUpHasCloseOnBackdropClick
    );
    setCustomWidth(INITIAL_STATES.customWidth);
    setTawkToAvailable(INITIAL_STATES.tawkToAvailable);
    switch (popUp?.popUpCode) {
      // case 'ScoreExplanation':
      //   active = <ScoreExplanation scoreExplanation={popUp.item} />;
      //   break;
      case 'reseaseQuarantineEmail':
        setActivePopUp(<ReleaseQuarantineEmail emailInfo={popUp.item} />);
        break;
      case 'removeQuarantineEmail':
        setActivePopUp(<RemoveQuarantineEmail emailInfo={popUp.item} />);
        break;
      case 'SentEmailTo':
        setActivePopUp(
          <SentEmailToModal
            modalTitle={popUp.item.title}
            emailType={popUp.item.type}
            domainName={popUp.item.data?.domainName}
          />
        );
        break;
      // case 'addPartnerClientPopup':
      //   setActivePopUp(<AddPartnerClientPopup />);
      //   break;
      case 'welcomePopUp':
        setPopUpHasCloseOnBackdropClick(false);
        setTawkToAvailable(true);
        setActivePopUp(<WelcomePopup />);

        break;
      case 'requirePayment':
        setActivePopUp(<RequirePayment requiredPayment={popUp.item} />);
        break;
      case 'tutorial':
        setActivePopUp(<Tutorial tutorial={popUp.item} />);
        break;
      case 'removeEmail':
        setActivePopUp(<RemoveEmail hashedEmail={popUp.item} />);
        break;
      case 'removeEmails':
        setActivePopUp(<RemoveEmails selectedTeamMembers={popUp.item} />);
        break;
      case 'addWebsite':
        setActivePopUp(<AddWebsite />);
        break;
      case 'removeWebsite':
        setActivePopUp(<RemoveWebsite domain={popUp.item} />);
        break;
      case 'checkPhisingReceptionEmail':
        setActivePopUp(
          <CheckPhisingReceptionEmail selectedCampaignType={popUp.item} />
        );
        break;
      case 'removeCampaign':
        setActivePopUp(<RemoveCampaign />);
        break;
      case 'websiteSecurityPopup':
        setActivePopUp(<WebsiteSecurityPopup faqData={popUp.item} />);
        break;
      case 'textWithoutButtons':
        setActivePopUp(<TextWithoutButtons />);
        break;
      case 'addCSVUserInEmailList':
        setActivePopUp(<AddCSVUserInEmailList />);
        break;
      case 'addUserInEmailList':
        setActivePopUp(<AddUserInEmailList isAdd />);
        break;
      case 'editUserInEmailList':
        setActivePopUp(<AddUserInEmailList user={popUp.item} />);
        break;
      case 'maxUsersInEmailList':
        setActivePopUp(<MaxUsersInEmailListPopUp maxUser />);
        break;
      case 'maxAdminsInEmailList':
        setActivePopUp(<MaxUsersInEmailListPopUp />);
        break;
      case 'maxPhisingCampaignsPopUp':
        setActivePopUp(<MaxPhishingCampaignsPopUp />);
        break;
      case 'maxDomainsPopUp':
        setActivePopUp(<MaxDomainsPopUp />);
        break;
      case 'removeSupplier':
        setActivePopUp(<RemoveSupplier domain={popUp.item} />);
        break;
      case 'addSupplier':
        setActivePopUp(<AddSupplier isAdd />);
        break;
      case 'editSupplier':
        setActivePopUp(<AddSupplier user={popUp.item} />);
        break;
      case 'notification':
        setPopUpHasCloseOnBackdropClick(!popUp.backgroundClosable);
        setActivePopUp(<NotificationsPopUp notificationData={popUp.item} />);
        break;

      case 'licensesSentbyEmployee':
        // setCustomWidth('780px');
        setActivePopUp(
          <LicensesSentByEmployeePopUp employeeLicenses={popUp.item} />
        );
        break;
      case 'emailVerificationExpired':
        setPopUpHasCloseOnBackdropClick(false);
        setActivePopUp(
          <EmailVerificationExpired notificationData={popUp.item} />
        );
        break;
      // case 'ChooseHowToReportIncident':
      //   active = <ChooseHowToReportIncident />;
      //   break;
      // case 'NotifyIncidentByPhone':
      //   active = <NotifyIncidentByPhone />;
      //   break;
      // case 'NotifyIncidentOnline':
      //   active = <NotifyIncidentOnline />;
      //   break;
      // case 'ReportOfIncidentSubmitted':
      //   active = <ReportOfIncidentSubmitted />;
      //   break;
      case 'breachesNotifyAffected':
        setActivePopUp(<BreachesNotifyAffected />);
        break;
      case 'installProtections':
        setCustomWidth('600px');
        setActivePopUp(
          <SendProtectionsEmails selectedTeamMembersEmails={popUp.item} />
        );
        break;
      case 'tagPicker':
        setActivePopUp(<TagPicker teamMembersHashedEmails={popUp.item} />);
        break;
      case 'changePassword':
        setActivePopUp(<ChangePassword />);
        break;
      case 'whatIncludesMyPlan':
        setActivePopUp(<WhatIncludesMyPlan />);
        break;
      case 'editCreditCard':
        setActivePopUp(<StripeEditCard />);
        break;
      case 'addBillingAddress':
        setActivePopUp(<AddBillingInformation />);
        break;
      case 'callUs':
        setActivePopUp(<CallMePopup />);
        break;
      case 'paymentDetails':
        setActivePopUp(<PaymentDetails payment={popUp.item} />);
        break;
      // case 'noButtons':
      //   setActivePopUp(<NoButtons />);
      //   break;
      default:
        setActivePopUp(null);
        break;
    }
  }, [popUp?.popUpCode]);

  return (
    <Modal
      open={!!activePopUp}
      footer={false}
      closable={false}
      destroyOnClose
      maskClosable={popUpHasCloseOnBackdropClick}
      mask
      styles={{
        mask: {
          background: 'rgba(0, 0, 0, 0.4)',
        },
      }}
      zIndex={2000000004}
      width={customWidth}
      hasCloseOnBackdropClick={popUpHasCloseOnBackdropClick}
      onCancel={closeModal}>
      {activePopUp}
    </Modal>
  );
};

PopUp.defaultProps = {
  popUp: { popUpCode: 'removeEmail', item: 'email' },
};

PopUp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  popUp: PropTypes.object,
};

const mapStateToProps = (state) => ({
  popUp: state.popUp,
});

export default connect(mapStateToProps, { showPopUp })(PopUp);
