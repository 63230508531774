import styled from '@emotion/styled';

const SCQuarantineFilterContainer = styled.div`
  flex-direction: column;
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  .QuarantineDestinationFilterContainer_MainContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }
`;

export default SCQuarantineFilterContainer;
