import React, { useState } from 'react';
import TagFilter from '../TagFilter/TagFilter';
import SCTagFilterContainer from './TagFilterContainer.style';

const TagFilterContainer = ({
  avoidFilterRolesAsTag,
  onChangeFilterTagCallback,
  opened,
  hasCustomButtonElements,
  hasPreviousFilter,
}) => {
  const [filters, setFilters] = useState([[]]);

  const applyTagFilterChanges = (filterChanges) => {
    const auxFilterChanges = filterChanges.length === 0 ? [[]] : filterChanges;
    setFilters(auxFilterChanges);
    if (onChangeFilterTagCallback) {
      onChangeFilterTagCallback(auxFilterChanges);
    }
  };

  const addNewFilter = () => {
    applyTagFilterChanges([...filters, []]);
  };

  const setFilter = (newFilter, newFilterIndex) => {
    const filtersAux = [...filters];
    filtersAux[newFilterIndex] = [...newFilter];
    applyTagFilterChanges(filtersAux);
  };

  const unsetFilter = (filterIndex) => {
    const filtersAux = [...filters];
    filtersAux.splice(filterIndex, 1);

    applyTagFilterChanges(filtersAux);
  };

  return (
    <SCTagFilterContainer
      opened={opened}
      hasCustomButtonElements={hasCustomButtonElements}>
      <div className="TagFilterContainer_MainContainer">
        {filters.map((filter, index) => {
          return (
            <TagFilter
              filter={filter}
              index={hasPreviousFilter ? index + 1 : index}
              onModify={(newFilter) => {
                setFilter(newFilter, index);
              }}
              onRemove={() => {
                unsetFilter(index);
              }}
              avoidFilterRolesAsTag={avoidFilterRolesAsTag}
            />
          );
        })}
        <TagFilter
          filter={undefined}
          onModify={(_) => {
            addNewFilter();
          }}
        />
      </div>
    </SCTagFilterContainer>
  );
};

export default TagFilterContainer;
