import styled from '@emotion/styled';

const SCQuarantineDestinationFilter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
  border-radius: 5px;

  .QuarantineDestinationFilter_MainContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 15px 0px;
  }

  .QuarantineDestinationFilter_ContentContainer {
    display: flex;
    align-items: flex-start;

    gap: 10px;
  }

  .QuarantineDestinationFilter_CloseActionContainer {
    display: flex;
    align-items: center;
    height: 35px;
  }

  .QuarantineDestinationFilter_InfoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    color: var(--bluish-grey);
  }

  .QuarantineDestinationFilter_IndexContainer {
    padding-left: 20px;
  }

  .QuarantineDestinationFilter_Selections {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    gap: 8px;
  }

  .QuarantineDestinationFilter_Selection {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;

    > p {
      color: var(--bluish-grey);
    }
  }

  .QuarantineDestinationFilter_LabelContainer {
    white-space: nowrap;

  }  
}
`;

export default SCQuarantineDestinationFilter;
