import styled from '@emotion/styled';

const SCMailDetailsCard = styled.div`
  .MailDetailsCard_MainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MailDetailsCard_Details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .MailDetailsCard_Score {
    display: flex;
    align-items: center;
  }

  .MailDetailsCard_Row {
    display: flex;
    flex-direction: row;

    .MailDetailsCard_InfoContainer {
      width: 50%;
    }
  }

  .MailDetailsCard_InfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 15px;
    }
  }

  .MailDetailsCard_Info {
    display: flex;
    flex-direction: column;

    span,
    p {
      font-size: 14px;
      margin: 0;
      color: var(--black);
      line-height: 1;
    }
    p {
      font-weight: 700;
    }
    span {
      line-height: 1.2;
    }
  }
`;

export default SCMailDetailsCard;
