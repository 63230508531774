import styled from '@emotion/styled';

const SCPartnerClientControlPanelHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ hasPadding }) => (hasPadding ? '15px 30px' : '0')};
  background: transparent;
  border-radius: 5px;
  text-align: left;

  > a {
    flex-shrink: 0;
  }
`;

export default SCPartnerClientControlPanelHeader;
