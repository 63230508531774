import styled from '@emotion/styled';

const SCNavbar = styled.nav`
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.65);
  backdrop-filter: blur(50px);
  z-index: 9999;
  top: 0px;

  .burger-menu {
    z-index: 99;
    position: absolute;
    top: 37.5px;
    right: 25px;
  }

  .cyber-guardian-logo {
    max-width: 125px;
    margin-left: 15px;
    cursor: pointer;
  }

  .santander-logo {
    max-width: 190px;
    margin-right: 15px;
  }

  @media (min-width: 769px) {
    .burger-menu {
      display: none;
    }

    .cyber-guardian-logo {
      max-width: 160px;
      margin-left: 40px;
    }
  }
`;

export default SCNavbar;
