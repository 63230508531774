import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_HISTORYPERSISTENCE } from '../../redux/actions/types';

function useHistoryPersistence(updateStore = false) {
  const location = useLocation();
  const dispatch = useDispatch();
  const historyPersistence = useSelector((redux) => redux.historyPersistence);

  const shouldUpdate = () => updateStore;
  const existsLocation = () => location.pathname;
  const isNewRoute = () => {
    return historyPersistence?.currentRoute !== location.pathname;
  };

  useEffect(() => {
    if (shouldUpdate() && existsLocation() && isNewRoute()) {
      dispatch({
        type: SET_HISTORYPERSISTENCE,
        payload: location.pathname,
      });
    }
  }, [location]);

  return {
    currentRoute: historyPersistence?.currentRoute,
    previousRoute: historyPersistence?.prevRoute,
  };
}

export default useHistoryPersistence;
