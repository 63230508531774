import { faInboxFull } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuarantineEmails } from '../../../hooks/useQuarantineEmails/useQuarantineEmails';
import { useTranslation } from '../../../i18n';
import {
  getColumnChartOptions,
  getColumnChartSeries,
} from '../../../utils/chartFactory/chartFactory';
import LabelDetail from '../../molecules/LabelDetail/LabelDetail';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMailboxSecurityInfo from './MailboxSecurityInfo.style';

const MailboxSecurityInfo = ({ filterByType = () => {} }) => {
  const i18n = useTranslation();
  const { quarantineReasons, quarantineReasonsCounter } = useQuarantineEmails();

  const [clickEvent, setClickEvent] = useState({
    index: undefined,
    type: undefined,
  });

  const [requestedType, setRequestedType] = useState();
  const [requestedTypeIndex, setRequestedTypeIndex] = useState();

  const handleSelection = (newType, newIndex) => {
    if (newIndex !== requestedTypeIndex) {
      setRequestedTypeIndex(newIndex);
      setRequestedType(newType);
    } else {
      setRequestedTypeIndex(undefined);
      setRequestedType(undefined);
    }
  };

  useEffect(() => {
    filterByType(requestedType);
  }, [requestedType]);

  useEffect(() => {
    if (clickEvent.type) {
      handleSelection(clickEvent.type, clickEvent.index);
    }
  }, [clickEvent]);

  const chartSeries = getColumnChartSeries({
    name: i18n.t('mailbox-security.mail.info.quantity'),
    data: quarantineReasonsCounter,
  });

  const chartOptions = getColumnChartOptions({
    xAxisCategory: quarantineReasons,
    xAxisLabelFormatter: (val) => {
      return i18n.t(`mailbox-security.mail.table.content.reasons.${val}`);
    },
    yAxisLabelFormatter: (val) => {
      // eslint-disable-next-line no-bitwise
      return val | 0;
    },
    fillColors: quarantineReasons.map(() => {
      return 'var(--application-background-color)';
    }),
    onClickCallback: (event, chartContext, config) => {
      if (config.dataPointIndex >= 0) {
        const quarantineRequestedType =
          config.config.xaxis.categories[config.dataPointIndex];
        setClickEvent({
          index: config.dataPointIndex,
          type: quarantineRequestedType,
        });
      }
    },
  });

  return (
    <SCMailboxSecurityInfo selectedColumn={requestedTypeIndex}>
      <SectionCard
        headerIcon={faInboxFull}
        headerTitle={i18n.t('mailbox-security.mail.info.title')}>
        {chartSeries && chartOptions && (
          <div className="MailboxSecurityInfo_MainContainer">
            <Chart
              type="bar"
              options={chartOptions}
              series={chartSeries}
              height={247}
            />

            <div className="MailboxSecurityInfo_ResumeContainer">
              {chartOptions.xaxis.categories.map((category, index) => {
                return (
                  <div className="MailboxSecurityInfo_ResumeElem">
                    <LabelDetail
                      name={i18n.t(
                        `mailbox-security.mail.table.content.reasons.${category}`
                      )}
                      value={chartSeries[0].data[index]}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </SectionCard>
    </SCMailboxSecurityInfo>
  );
};

export default MailboxSecurityInfo;
