import { faUser, faXmark } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Input from '../../atoms/Input/Input';
import SCTagFilter from './QuarantineDestinationFilter.style';

const QuarantineDestinationFilter = ({
  onModify,
  onRemove,
  initialQuarantineDestinations = [],
}) => {
  const i18n = useTranslation();
  const [filter, setFilter] = useState([]);

  const [selectedQuarantineDestinations, setSelectedQuarantineDestinations] =
    useState([]);

  const isFilterChanged = () => {
    return (
      JSON.stringify(filter) !== JSON.stringify(selectedQuarantineDestinations)
    );
  };

  useEffect(() => {
    setSelectedQuarantineDestinations(initialQuarantineDestinations);
  }, [initialQuarantineDestinations]);

  useEffect(() => {
    if (filter && isFilterChanged()) {
      setFilter(selectedQuarantineDestinations);
      onModify(selectedQuarantineDestinations);
    }
  }, [selectedQuarantineDestinations]);

  useEffect(() => {
    if (filter && isFilterChanged()) {
      setSelectedQuarantineDestinations(selectedQuarantineDestinations);
    }
  }, [filter]);

  const addNewFilterQuarantineDestination = (newQuarantineDestination) => {
    setSelectedQuarantineDestinations([
      ...selectedQuarantineDestinations,
      newQuarantineDestination,
    ]);
  };

  const removeFilterQuarantineDestination = (
    selectedQuarantineDestination,
    index
  ) => {
    if (!selectedQuarantineDestination) {
      const selectedQuarantineDestinationAux = [
        ...selectedQuarantineDestinations,
      ];
      selectedQuarantineDestinationAux.splice(index, 1);
      setSelectedQuarantineDestinations(selectedQuarantineDestinationAux);
    }
  };

  const replaceFilterQuarantineDestination = (
    newQuarantineDestination,
    index
  ) => {
    const selectedQuarantineDestinationAux = [
      ...selectedQuarantineDestinations,
    ];
    selectedQuarantineDestinationAux[index] = newQuarantineDestination;
    setSelectedQuarantineDestinations(selectedQuarantineDestinationAux);
  };

  return (
    <SCTagFilter>
      <div className="QuarantineDestinationFilter_MainContainer">
        {filter && (
          <>
            <div className="QuarantineDestinationFilter_ContentContainer">
              <div className="QuarantineDestinationFilter_InfoContainer">
                <div className="QuarantineDestinationFilter_IndexContainer">
                  {1}.
                </div>
                <Icon iconName={faUser} />
                <div className="QuarantineDestinationFilter_LabelContainer">
                  {i18n.t(
                    'destinationsFiltering.destinationFilter.tagSelectionLabel'
                  )}
                </div>
              </div>
              <div className="QuarantineDestinationFilter_Selections">
                {selectedQuarantineDestinations.map(
                  (selectedQuarantineDestination, index) => (
                    <div className="QuarantineDestinationFilter_Selection">
                      <Input
                        size="customMid"
                        design="round"
                        autoFocus={
                          index === selectedQuarantineDestinations.length - 1
                        }
                        onfocusout={removeFilterQuarantineDestination(
                          selectedQuarantineDestination,
                          index
                        )}
                        inputType="text"
                        value={selectedQuarantineDestination}
                        onChange={(e) => {
                          replaceFilterQuarantineDestination(
                            e.target.value,
                            index
                          );
                        }}
                      />
                      <p>
                        {i18n.t('destinationsFiltering.destinationFilter.and')}
                      </p>
                    </div>
                  )
                )}
                <Input
                  size="customMid"
                  design="round"
                  inputType="text"
                  inputPlaceholder={i18n.t(
                    'destinationsFiltering.destinationFilter.input.placeholder'
                  )}
                  onChange={(e) => {
                    addNewFilterQuarantineDestination(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="QuarantineDestinationFilter_CloseActionContainer">
              <Button
                onClick={() => {
                  onRemove();
                }}
                color="icon"
                type="button"
                icon={faXmark}
              />
            </div>
          </>
        )}
      </div>
    </SCTagFilter>
  );
};

export default QuarantineDestinationFilter;
