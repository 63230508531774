import {
  faFilterList,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import DatePickerApp from '../../atoms/DatePicker/DatePicker';
import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';
import Table from '../../atoms/Table/Table';
import QuarantineDestinationFilterContainer from '../QuarantineDestinationFilterContainer/QuarantineDestinationFilterContainer';
import TagFilterContainer from '../TagFilterContainer/TagFilterContainer';
import SCTableContainer from './TableContainer.style';

const TableContainer = ({
  onChangeValue,
  filterByQuarantineBetweenDates,
  filterByQuarantineDestinations,
  filterByQuarantineType,
  selectedFilterByQuarantineType,
  selectedFilterByQuarantineBetweenDates,
  selectedFilterByQuarantineDestinations,
  quarantineTypes,
  columns,
  rows,
  onChangeFilterTag,
  avoidFilterRolesAsTag,
  hasHorizontalScroll,
  emptyMessage,
  rowHeight,
  hasRowIcon,
  hasRowSelect,
  rowIdentifier,
  rowHasBeenSelected,
  sortOptions,
  isLoading,
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  customSideElements,
  customBottomElements,
  size,
  autoWidth,
}) => {
  const i18n = useTranslation();
  const [tagFilterOpened, setTagFilterOpened] = useState(false);

  useEffect(() => {
    if (!tagFilterOpened) {
      setTagFilterOpened(
        selectedFilterByQuarantineDestinations &&
          selectedFilterByQuarantineDestinations.length > 0
      );
    }
  }, [selectedFilterByQuarantineDestinations]);

  return (
    <SCTableContainer
      size={size}
      rowHasBeenSelected={rowHasBeenSelected}
      rowsLength={rows.length}
      totalCount={totalCount}
      pageSize={pageSize}
      onChangeFilterTag={onChangeFilterTag}
      hasHorizontalScroll={hasHorizontalScroll}
      hasHeaders={columns}
      rowHeight={rowHeight}>
      {(onChangeValue ||
        onChangeFilterTag ||
        filterByQuarantineDestinations ||
        filterByQuarantineType ||
        customSideElements) && (
        <div className="table-filter">
          {filterByQuarantineType && quarantineTypes && (
            <Select
              selectedValue={
                quarantineTypes.find((type) => {
                  return type.value === selectedFilterByQuarantineType;
                }).label
              }
              onChange={(quarantineType) => {
                filterByQuarantineType(
                  quarantineTypes.find((type) => {
                    return type.label === quarantineType;
                  }).value
                );
              }}
              values={quarantineTypes.map((quarantineType) => {
                return quarantineType.label;
              })}
              design="round"
            />
          )}
          {onChangeValue && (
            <Input
              inputType="text"
              inputPlaceholder={i18n.t('common.search')}
              onChangeValue={onChangeValue}
              icon={faMagnifyingGlass}
              size={size || 'short'}
              design="round"
            />
          )}
          {filterByQuarantineDestinations && (
            <Button
              text={i18n.t(
                'destinationsFiltering.actions.filterByDestinations'
              )}
              onClick={() => {
                setTagFilterOpened(!tagFilterOpened);
              }}
              color={tagFilterOpened ? 'bluishGrey' : 'white'}
              type="button"
              size={size || 'mid'}
              icon={faFilterList}
            />
          )}
          {filterByQuarantineBetweenDates && (
            <DatePickerApp
              disabledDatesBefore={moment(
                new Date().setDate(new Date().getDate() - 15)
              )}
              disabledDatesAfter={moment(new Date())}
              isRangePicker
              defaultValue={selectedFilterByQuarantineBetweenDates}
              onChange={(e) => {
                filterByQuarantineBetweenDates(
                  e && e.length === 2
                    ? e.map((date, index) => {
                        let auxDate = date;
                        auxDate = auxDate
                          .set('hour', index === 0 ? 0 : 23)
                          .set('minute', index === 0 ? 0 : 59)
                          .set('second', index === 0 ? 0 : 59);
                        return auxDate.toDate();
                      })
                    : []
                );
              }}
            />
          )}
          {onChangeFilterTag && !filterByQuarantineDestinations && (
            <Button
              text={i18n.t('tagFiltering.actions.filterByEmployee')}
              onClick={() => {
                setTagFilterOpened(!tagFilterOpened);
              }}
              color={tagFilterOpened ? 'bluishGrey' : 'white'}
              type="button"
              size={size || 'mid'}
              icon={faFilterList}
            />
          )}
          {onChangeFilterTag && customSideElements && (
            <>
              <span>|</span>{' '}
            </>
          )}
          {customSideElements && <>{customSideElements}</>}
        </div>
      )}

      <div className="table-filterInfo">
        {filterByQuarantineDestinations && (
          <QuarantineDestinationFilterContainer
            selectedQuarantineDestinations={
              selectedFilterByQuarantineDestinations
            }
            onChangeQuarantineDestinationCallback={
              filterByQuarantineDestinations
            }
            opened={tagFilterOpened}
          />
        )}
        {onChangeFilterTag && (
          <TagFilterContainer
            avoidFilterRolesAsTag={avoidFilterRolesAsTag}
            onChangeFilterTagCallback={onChangeFilterTag}
            opened={tagFilterOpened}
            hasCustomButtonElements={customBottomElements}
            hasPreviousFilter={!!filterByQuarantineDestinations}
          />
        )}

        {tagFilterOpened && rowHasBeenSelected && <hr className="solid" />}

        {customBottomElements}
      </div>

      <div className="table-container">
        <Table
          columns={columns}
          rows={rows}
          emptyMessage={emptyMessage}
          hasRowIcon={hasRowIcon}
          onChangeFilterTag={onChangeFilterTag}
          rowIdentifier={rowIdentifier}
          rowHasBeenSelected={rowHasBeenSelected}
          hasRowSelect={hasRowSelect}
          sortOptions={sortOptions}
          isLoading={isLoading}
          autoWidth={autoWidth}
        />
      </div>

      {/* {totalCount > pageSize && (
        <div className="pagination-container">
          <Pagination
            onPageChange={onPageChange}
            totalCount={totalCount}
            siblingCount={siblingCount}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </div>
      )} */}
    </SCTableContainer>
  );
};

export default TableContainer;
