import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as emailProtectionService from '../../services/emailProtection/emailProtection.service';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { useTranslation } from '../../i18n';
import { ERRORS } from '../../utils/constants/errors';
// eslint-disable-next-line import/prefer-default-export
export function useQuarantineEmails() {
  const client = useSelector((redux) => redux.client);
  const quarantineEmails = useSelector((redux) => redux.quarantineEmails);
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [areMXRecordsInitialized, setAreMXRecordsInitialized] = useState(false);

  const quarantineReasons = [
    ...new Set(
      quarantineEmails?.map((quarantineEmail) => quarantineEmail.type)
    ),
  ];

  const quarantineReasonsCounter = quarantineReasons.map(
    (quarantineReason) =>
      quarantineEmails.filter(
        (quarantineEmail) => quarantineEmail.type === quarantineReason
      ).length
  );

  const init = async () => {
    setLoading(true);

    emailProtectionService
      .init()
      .then((resp) => {
        setError(false);
        setAreMXRecordsInitialized(true);
      })
      .catch((e) => {
        const { data } = e.response;
        setError(true);
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            closeButtonText: i18n.t('common.close'),
            text:
              data === ERRORS.DOMAIN_ALREADY_EXISTS
                ? i18n.t('mailbox-security.unableActivateProtection')
                : i18n.t('common.errorTryLater'),
            ...(data === ERRORS.DOMAIN_ALREADY_EXISTS && {
              additionalButtonText: i18n.t('contactUs.contactSupport'),
              onButtonClick: () => Tawk_API.toggle(),
            }),
          })
        );
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMxRecords = async () => {
    setLoading(true);

    return emailProtectionService
      .getMxRecords()
      .then((resp) => {
        setError(false);
        return resp?.data?.data?.mxs;
      })
      .catch((e) => {
        setError(true);
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteQuarantineEmail = async ({ emailId }) => {
    setLoading(true);
    return emailProtectionService
      .remove(emailId)
      .then((resp) => {
        setError(false);
        setLoading(false);

        return resp.status === 204 ? undefined : resp.data;
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        throw e;
      });
  };

  const releaseQuarantineEmail = async ({ emailId }) => {
    setLoading(true);
    return emailProtectionService
      .release(emailId)
      .then((resp) => {
        setError(false);
        setLoading(false);

        return resp.status === 204 ? undefined : resp.data;
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        throw e;
      });
  };

  return {
    quarantineEmails,
    quarantineEmailsAvailable: !!client?.protection?.email,
    deleteQuarantineEmail,
    releaseQuarantineEmail,
    init,
    getMxRecords,
    quarantineReasons,
    quarantineReasonsCounter,
    loading,
    error,
    areMXRecordsInitialized,
  };
}
